<br/>

<div *ngIf="loading" align="center">
    <br/>
    <div class="loader"></div>
</div>

<form *ngIf="!loading">
    <div style="padding-right: 15px; margin-bottom: 25px;">
        <div class="row" *ngIf = 'isFulfilPurchase'>
            <div class="col-md-4 input-group">
                <input id="searchKeyword" class="form-control" (input)="searchKeyword = $event.target.value" value="{{searchKeyword}}" placeholder="Search by Name, Number">
                <span class="input-group-btn">
                    <input class="btn btn-info simpleRfIdColor" type="submit"  (click)="getOrders()" style="display: inline" [disabled]="searchKeyword.length == 0" value="Search" />
                </span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-3 input-group">
                <span class="status-text">Status:</span>
                <select id="filter" name="status" [(ngModel)]="status" (change)="getFilteredOrders()" class="form-control" [ngModelOptions]="{standalone: true}" >
                    <option *ngFor="let status of ordersStatuses, let i = index" [ngValue]='status == "Waiting/Received" ? "" : status'>{{status}}</option>
                </select>
            </div>
            <div class="col-md-3" style="padding-right: 0;">
                <div class="input-group">
                    <select style="width: 75%;" name="action" [(ngModel)]="action">
                        <option selected value="">Choose action</option>
                        <option *ngIf="status != 'Shipping'" value="submitFulfilPurchase">Submit as Purchase</option>
                        <!--<option value="print">Print</option>-->
                        <option *ngIf="status != 'Shipping'" value="submitAsTransfer">Submit as Transfer</option>
                    </select>
                    <span class="input-group-btn">
                        <button type="button" [disabled]="!action || !isChekedOrder" class="btn btn-info simpleRfIdColor" (click)="bulkAction()">Confirm</button>
                    </span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf = '!isFulfilPurchase'>
            <div class="col-md-2">
                <label>Filter by status:</label>
            </div>
            <div class="col-md-2">
                <select id="filter" name="status" [(ngModel)]="status" (change)="getFilteredOrders()" class="form-control" [ngModelOptions]="{standalone: true}" >
                    <option *ngFor="let status of ordersStatuses, let i = index" [ngValue]='status == "All" ? "" : status'>{{status}}</option>
                </select>
                <br/>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-info" (click)="submit()" [disabled]="!printButtonEnabled">Print</button>
            </div>
            <div class="col-md-3">
                <input id="searchKeyword" class="form-control" (input)="searchKeyword = $event.target.value" value="{{searchKeyword}}" placeholder="Search by Name, Number">
            </div>
            <div class="col-md-1">
                <button class="btn btn-primary btn-info" (click)="getOrders()" style="display: inline" [disabled]="searchKeyword.length == 0">Search</button>
            </div>
            <div class="col-md-2" *ngIf='!data.isFulfil'>
                <div class="btn-group">
                    <button *ngIf = 'isPurchaseTab' class="btn btn-primary btn-blue" [ngClass]="{ 'active' : type == purchseOrdersService._purchaseType }" (click)="getPurchase()">Purchase</button>
                    <button *ngIf = 'isPurchaseTab' class="btn btn-primary btn-blue" [ngClass]="{ 'active' : type == purchseOrdersService._returnType }" (click)="getReturn()">Return</button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Number</th>
                    <th class="order-table-date-header" (click)="sortByDate()">Date 
                    @if(isSotedByDateAscending){
                        <img src="./assets/images/sort-up.png">
                    } @else {
                        <img src="./assets/images/sort-down.png">
                    }
                    </th>
                    <th>Name</th>
                    <th *ngIf="type != purchseOrdersService._purchaseType">Origin</th>
                    <th *ngIf="type != purchseOrdersService._transferType">Supplier</th>
                    <th *ngIf="type == purchseOrdersService._transferType || type == purchseOrdersService._purchaseType">Destination</th>
                    <th class="status-table">Status</th>
                    <th class="status-table" *ngIf='!data.isFulfil'>Received at</th>
                    <!-- <th></th> -->
                    @if( isFulfilPurchase && status == 'Shipping'){
                        <td></td>
                    } @else {
                        <th class="table-text-end">
                            <input name="allSelected" type="checkbox" [ngModel]="allSelected" (click)="selectAll()"/>
                        </th>
                    }
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of orders; let ind = index" ng-class="order.class" class="order-raw">
                    <td>{{order.Number}}</td>
                    <td *ngIf="order.CreatedAt == null">-</td>
                    <td *ngIf="order.CreatedAt != null">{{order.CreatedAt | date:'medium':'':data.locale}}</td>
                    <td title="Click to navigate to order in {{data.platform}}">
                        <a href="{{order.Link}}" target="_blank" style="text-decoration: none;">{{order.Name}}</a>
                    </td>
                    <td *ngIf="type == purchseOrdersService._returnType">{{order.Outlet}}</td>
                    <td *ngIf="type == purchseOrdersService._transferType">{{order.SourceOutlet}}</td>                    
                    <td *ngIf="order.SupplierName && type != purchseOrdersService._transferType">{{order.SupplierName}}</td>
                    <td *ngIf="!order.SupplierName && type != purchseOrdersService._transferType">-</td>
                    <td *ngIf="type != purchseOrdersService._returnType">{{order.Outlet}}</td>
                    <td class="status-table">{{order.Status}}</td>
                    <td class="status-table" *ngIf="order.ReceivedAt != null && !data.isFulfil">{{order.ReceivedAt | date:'mediumDate'}}</td>
                    <td class="status-table" *ngIf="order.ReceivedAt == null && !data.isFulfil">-</td>
                    <!-- <td *ngIf="type == purchseOrdersService._purchaseType">
                        <button class="btn btn-info" (click)="openPartialPrintModal(order, !isOrderInfo)" [disabled]="!data.isAvailablePrint">Print</button>
                    </td> -->
                    <!-- <td>
                        {{order.Printed}}<a class="has-notify-icon-small" [ngClass]="{printed: order.PrintStatus === 'Printed'}" title="Printed"></a>
                    </td> -->
                    @if( isFulfilPurchase && status == 'Shipping'){
                        <td></td>
                    } @else {
                        <td class="table-text-end">
                            <input name="order[{{ind}}].Selected" type="checkbox" [(ngModel)]="order.Selected" (change)="select()" [disabled]="!isPrintable(order)"/>
                        </td>
                    }
                </tr>
            </tbody>
        </table>
    </div>
</form>