import { Component, OnInit, Input, Injectable, OnDestroy, DoCheck } from '@angular/core';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { UserSettingsInfoService } from 'app/settings/settings.service';
import { filters } from 'app/core/filters-names';
import { LayoutFormatType, State, PrintingService, SearchProductModel} from 'app/printing/printing.service';
import * as XLSX from 'xlsx';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarService } from 'app/progress-bar/progress-bar.service';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { OrderTypes, UrlBuilderService } from 'app/core/url-builder';
import { HangFireService } from 'app/core/hangfire/hangfire.service';
import { urls } from 'app/core/urls';
import { Observable } from 'rxjs';
import { ProductCopyModelRequest, ProductCopyModelResponse, ProductService } from 'app/products/products.service';
import { switchMap } from 'rxjs/operators';
import { ButtonFilterPipe } from 'app/filters/button-filter.pipe';
import { FilterPipe } from 'app/filters/filter';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { InventorySyncService } from '../inventory-sync.service';
import { InventoryStorageService } from '../inventory-storage.service';
import { ProgressBarDataModel } from 'app/progress-bar/progress-bar-data-model';
import { InventoryPrintModalComponent } from '../inventory-print-modal/inventory-print-modal.component';
import { InventoryOrderModalComponent } from '../inventory-order-modal/inventory-order-modal.component';

enum InventorySource {
  Mismatch,
  Match
};

@Component({
    selector: 'app-inventory-sync',
    templateUrl: './inventory-sync.component.html',
    styleUrls: ['../inventory-sync.component.css', '../../fonts/icon-font.css'],
    providers: [FilterPipe, ButtonFilterPipe],
    standalone: false
})

@Injectable()
export class InventorySyncComponent implements OnInit, DoCheck, OnDestroy {
  items: Array<any> = [];
  showBinLocation: boolean;
  filteredItems: Array<any> = [];
  loading = false;
  syncAll = false;
  makeVisible = false;
  countToSync = 0;
  itemsToSync = 0;
  isVisible: boolean;
  allowMakeVisible = false;
  lastAuditDate = null;
  isOrdersAccounted = true;
  selectAllDisabled = false;
  searchString = '';
  showInventoryTypeButtons = false;
  line = filters.default;
  reportsCount: number;
  eCommerceTitle: string;
  syncTab = false;
  colorFilters: any = filters;
  inventoryStatus: string;
  fulfilSyncConfirmationIds: string;
  isSyncInProgress = false;
  showContent = false;
  @Input() data: RequiredChildsData;
  private inventoryStorage: InventoryStorageService;
  lastSyncCountChangedTime = 0;
  lastSyncedItemsCount: number;
  countItemsToPrint = 0;
  progressBarData: ProgressBarDataModel;
  progressBarLoadData: ProgressBarDataModel;
  isShowSpreadsheetPopup = false;
  isShowSpreadsheetButton = false;
  inputDate = null;
  currentDate: any;
  strCurrentDate: any;
  isSync = false;
  isLoadInProgress = false;
  productKey: string;
  itemsSearch: Array<any> = [];
  activeFilterColor: string;
  lastSelected = -1;
  activeSourceTab: InventorySource;
  InventorySource = InventorySource; // Workaround to make accessable from HTML
  excludeZero = true;
  isPrinterOnline: boolean;
  syncOperation: ActivityOperationType;
  checkInventoryLoadedTimer?: any;
  

  public get isPrintingEnable() {
    if(!this.data.PrintingLayouts){
      return false;
    }
    const selectedLayout = this.data
      .PrintingLayouts
      .find(l => l.id == this.data.DefaultLayoutId);

      if (!selectedLayout) {
        return false;
      }

     const labelSupport = this.data.CurrentPrinter.isNewPrintClient
      || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.CurrentPrinter.isNewPrintClient)

    return this.data.isAvailablePrint && this.countItemsToPrint > 0 && !!selectedLayout && labelSupport && this.isPrinterOnline;
  }

  public get isPrintingEnableTitle() {
    if (!this.data.PrintingLayouts) {
      return "No labels";
    }
    const selectedLayout = this.data
    .PrintingLayouts
    .find(l => l.id == this.data.DefaultLayoutId);

    if (!selectedLayout) {
      return "Select label";
    }
    
    if (selectedLayout.format == LayoutFormatType.Sld && !this.data.CurrentPrinter.isNewPrintClient) {
      return 'The Sld label format supported only by new printing';
    }

    if (!this.data.isAvailablePrint) {
      return 'No printer connected';
    }

    if (this.countItemsToPrint <= 0) {
      return 'Select any product';
    }

    if (!selectedLayout) {
      return 'Select label';
    }

    if (!this.isPrinterOnline) {
      return 'Selected printer is offline. Select online printer.';
    }
    
    return 'Run to print selected products';
  }

  ngbModalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    centered: true
  };

  isSortedByAvailableAscending = 'default';
  isSortedByPatnerAscending = 'default';

  constructor(private inventorySyncService: InventorySyncService, private filter: FilterPipe, private colorFilter: ButtonFilterPipe,
    private errorModalService: ErrorModalService,
    private confirmModalService: ConfirmModalService, private userSettingsService: UserSettingsInfoService,
    private PrintingService: PrintingService, private modalService: NgbModal, private progressBarService: ProgressBarService,
    private urlBuilder: UrlBuilderService, private hangFireService: HangFireService, private productsService: ProductService,) {
    this.inventoryStorage = InventoryStorageService.GetInstance(this.inventorySyncService, this.errorModalService, this.hangFireService);
  }
  ngOnDestroy(): void {
    if(this.checkInventoryLoadedTimer){
      clearInterval(this.checkInventoryLoadedTimer);
    }
  }

  async ngOnInit() {
    if(this.data.isShopify){
      this.errorModalService.open("Warning", "This tab will be removed by April 1st. Please use Compare OnHand going forward.");
    }
    this.showContent = false;
    this.progressBarData = new ProgressBarDataModel();
    this.progressBarLoadData = new ProgressBarDataModel();
    this.isShowSpreadsheetButton = false;
    this.productKey = this.data.UseBarcodeAsProductKey ? 'Barcode' : 'Sku';
    this.activeSourceTab = InventorySource.Mismatch;
    this.syncOperation= ActivityOperationType.SyncInventory;
    this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryMatch.length;

    this.progressBarData.locationExternalId = this.data.currentLocationExternalId;
    this.progressBarLoadData.locationExternalId = this.data.currentLocationExternalId;
    this.progressBarLoadData.isSync = false;
    this.isSyncInProgress = true;

    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.LoadInventory).subscribe(res => {
      this.progressBarLoadData.operationType = ActivityOperationType.LoadInventory;
      if (res) {
        this.progressBarLoadData.isTaskInProgress = res.IsInProgress;
        if (!res.IsInProgress && res.HangfireJobId) {
          if (res.StatusJob == 'Succeeded') {
            this.progressBarLoadData.hangFireSuccess = true;
            this.progressBarLoadData.hangfireJobId = res.HangfireJobId;
          } else if (res.StatusJob == 'Failed') {
            this.progressBarLoadData.hangFireFail = true;
            this.progressBarLoadData.errorMessage = 'last loading inventory';
            this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarLoadData.operationType).subscribe(r => {});
          }
        }
      }
      this.showContent = true;
    });

    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.SyncInventory).subscribe(res => {
      this.progressBarData.operationType = ActivityOperationType.SyncInventory;
      if (res) {
        this.progressBarData.isTaskInProgress = res.IsInProgress;
        if (!res.IsInProgress && res.HangfireJobId) {
          if (res.StatusJob == 'Succeeded') {
            this.progressBarData.hangFireSuccess = true;
            this.progressBarData.hangfireJobId = res.HangfireJobId;
          } else if (res.StatusJob == 'Failed') {
            this.progressBarData.hangFireFail = true;
            this.progressBarData.errorMessage = 'last syncing inventory';
            this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarData.operationType).subscribe(r => {});
          }
        }
        this.isSyncInProgress = false;
        this.showContent = true;
      }
    });

    this.loading = true;
    this.inventorySyncService.getLastAuditDate(this.data.currentLocationExternalId).subscribe(res => {
      this.inventoryStorage.inventoryStatus = res.InventoryStatus;
      this.inventoryStorage.lastAuditDate = res.LastAuditDate?.toString();
      this.inventoryStatus = this.inventoryStorage.inventoryStatus;
      this.lastAuditDate = this.inventoryStorage.lastAuditDate != null ? this.inventoryStorage.lastAuditDate : undefined;
      const dateNow = new Date();
      const year = dateNow.getFullYear();
      const month = dateNow.getMonth() + 1;
      const day = dateNow.getDate();
      this.currentDate = new Date(Date.UTC(year, month, day)).toUTCString();
      this.inputDate = this.lastAuditDate;
    },
      error => {
        this.loading = false;
        this.errorModalService.open('Error', 'Failed to load Last Reset date');
      });

    if (this.data.isBigCommerce) {
      const userSettings = await this.userSettingsService.getUserSettingsInfo();
      this.showBinLocation = userSettings.showBinLocation;
      this.loading = false;
    }

    this.checkInventoryLoaded();
    this.checkInventoryLoadedTimer = setInterval(() => this.checkInventoryLoaded(), 1000);

    this.loading = false;
  }

  ngDoCheck() {
    this.isPrinterOnline = this.data.CurrentPrinter?.status == State.Online;
    if (this.progressBarLoadData.hangFireSuccess) {
      this.progressBarLoadData.hangFireSuccess = false;
      this.getLoadResult();
    } else if (this.progressBarLoadData.hangFireFail) {
      this.progressBarLoadData.hangFireFail = false;
      this.errorModalService.open('Error', this.progressBarLoadData.errorMessage);
    }
    if (this.progressBarData.hangFireSuccess) {
      this.progressBarData.hangFireSuccess = false;
      this.getSyncResult();
    } else if (this.progressBarData.hangFireFail) {
      this.progressBarData.hangFireFail = false;
      this.errorModalService.open('Error', this.progressBarData.errorMessage);
    }
  }

  loadInProgress() {
    this.isLoadInProgress = true;
  }

  finishLoad() {
    this.isLoadInProgress = false;
    this.progressBarLoadData.isTaskInProgress = false;
    this.showContent = true;
  }

  inProgress() {
    this.isSyncInProgress = true;
    this.showContent = true;
  }

  finishSync() {
    if (this.isSync) {
      this.isSyncInProgress = false;
    this.progressBarData.isTaskInProgress = false;
    const itemsToSync = this.filteredItems.filter(i => {
      return i.sync;
    });
    itemsToSync.forEach(e => {
      this.items = this.items.filter(function (i) {
        return ('' + i.ProductId + i.VariantId) != ('' + e.ProductId + e.VariantId);
      });
    });
    this.showContent = true;
    }
  }

  changeTab(isReverted: boolean) {
    this.syncTab = !this.syncTab;
    if (!this.syncTab && isReverted) {
      this.getInventory();
    }
  }

  private filterItems(items) {
    return this.filter.transform(items, ['Title', 'Sku'], this.searchString.trim());
  }

  private getItemsForSearch() {
    this.filteredItems = this.filterItems(this.itemsSearch);
    return this.filteredItems;
  }

  public getInventoryTab() {

    const sources = [{
      items: this.items,
      callback: this.getMismatchInventory.bind(this)
    }, {
      items: this.inventoryStorage.inventoryMatch,
      callback: this.getMatchInventory.bind(this)
    }];

    for (let index = 0; index < sources.length; index++) {
      const source = sources[index];
      this.filteredItems = this.filterItems(source.items);
      if (this.filteredItems.length > 0) {
        source.callback();
        break;
      }
    }
  }

  private getCurrentMutchSource() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      return this.items;
    } else if (this.activeSourceTab == InventorySource.Match) {
      return this.inventoryStorage.inventoryMatch;
    }

    return [];
  }

  private getCurrentMutchCallback() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      return this.getMismatchInventory;
    } else if (this.activeSourceTab == InventorySource.Match) {
      return this.getMatchInventory;
    }

    return () => { };
  }

  search() {
    this.itemsSearch = this.getCurrentMutchSource();
    this.getItemsForSearch();
    this.countItemsToSync()
    this.syncAll = this.countToSync > 0 && this.filteredItems.length === this.countToSync;
    this.selectAllDisabled = this.filteredItems.length === 0;
  }

  private checkInventoryLoaded(): void {
    if (this.inventoryStorage.isInventoryMatchLoaded
      && this.inventoryStorage.isInventorySyncLoaded
      && !this.loading) {
      return;
    }
    this.isShowSpreadsheetButton = true;
    this.getCurrentMutchCallback().bind(this)();
  }

  getInventory() {
    this.inventorySyncService.EnqueuedItem(this.isVisible, this.data.currentLocationExternalId, urls.enqueuedLoadItemUrl).subscribe(res => {
      this.isLoadInProgress = true;
      setTimeout(() => this.startProgressBarLoad(), 1000);
      this.progressBarLoadData.isWasStarted = true;
      this.progressBarLoadData.operationType = ActivityOperationType.LoadInventory;
    });
  }

  private countItemsToSync() {
    this.countToSync = this.filteredItems.filter(it => it.sync).length;
    this.isShowSpreadsheetButton = this.countToSync > 0;
    this.countItemsToPrint = this.filteredItems.filter(it => it.sync && it.CurrentCount - it.NewCount > 0).length;
    const checkedItems = this.filteredItems.filter(it => it.sync);
    this.itemsToSync = checkedItems.reduce((sum, current) => sum + current.NewCount, 0);
  }

  private setItemsToSync() {
    this.countItemsToSync()
    this.allowMakeVisible = this.isVisible === false;
    this.selectAllDisabled = this.filteredItems.length === 0;
    this.syncAll = this.countToSync > 0 && this.filteredItems.filter(i => i.NewCount != i.CurrentCount).length === this.countToSync;
  }

  lineFilter(lineColor: string) {
    this.line = lineColor;
    if (lineColor !== filters.default) {
      this.filteredItems = this.colorFilter.transform(this.items, lineColor);
    } else {
      this.filteredItems = this.items;
    }

    this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
    this.activeFilterColor = null;
    if (lineColor === filters.default) {
      this.activeFilterColor = 'white';
    } else if (lineColor === filters.inflatedFilter) {
      this.activeFilterColor = 'red';
    } else if (lineColor === filters.deflatedFilter) {
      this.activeFilterColor = 'green';
    } else if (lineColor === filters.OoSFilter) {
      this.activeFilterColor = 'oss';
    }

    this.countToSync = 0;
    this.itemsToSync = 0;
    this.syncAll = false;

    this.countItemsToSync();

    if (this.filteredItems.length == this.countToSync && this.countToSync != 0) {
      this.syncAll = true;
    }

    this.selectAllDisabled = this.filteredItems.length == 0;
  }

  getMismatchInventory() {
    if (this.inventoryStorage.isInventorySyncLoaded) {
      this.items = this.inventoryStorage.inventorySync;
      this.isShowSpreadsheetButton = this.filteredItems.filter(i => i.sync).length > 0 ? true : false;
      this.loading = false;
      this.lineFilter(filters.default);
      this.isOrdersAccounted = this.inventoryStorage.isOrdersAccounted;
      this.inventoryStatus = this.inventoryStorage.inventoryStatus;
      this.lastAuditDate = this.inventoryStorage.lastAuditDate != null ? this.inventoryStorage.lastAuditDate : undefined;
      this.setItemsToSync();
      this.showInventoryTypeButtons = this.inventoryStorage.isFirstloaded;
      this.inventoryStorage.SetMismatchItemsCount();
      this.inventoryStorage.SetInventoryItemsCounts(this.items);

      if (this.inventoryStorage.isResetRecommended) {
        const error = 'The Quantity of Orders pending prevents calculation. Reset Inventory to allow the system to approximate current on-hand inventory against orders';
        this.errorModalService.open('Warning', error);
      }
    } else {
      this.loading = true;
    }
    this.activeSourceTab = InventorySource.Mismatch;
    this.search();
  }

  getMatchInventory() {
    if (this.inventoryStorage.isInventoryMatchLoaded) {
      this.filteredItems = this.inventoryStorage.inventoryMatch;
      this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryMatch.length;
      this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
      this.isShowSpreadsheetButton = this.filteredItems.filter(i => i.sync).length > 0 ? true : false;
      this.loading = false;
    } else {
      this.loading = true;
    }
    this.countToSync = 0;
    this.activeSourceTab = InventorySource.Match;
    this.search();
  }

  adjustSelection() {
    if (this.activeSourceTab == InventorySource.Match) {
      this.filteredItems.forEach(it => { it.sync = this.syncAll; });
    } else if (this.activeSourceTab == InventorySource.Mismatch) {
      this.filteredItems.forEach(it => { it.sync = it.NewCount == it.CurrentCount ? false : this.syncAll; });
    }
    this.countItemsToSync();
  }

  changeSelection(product) {
    if (product.sync) {
      product.sync = !product.sync;
      product.editable = false;
      product.isAdjusted = false;
    } else {
      product.sync = true;
    }

    if (product.sync) {
      this.countToSync++;
      this.itemsToSync += product.NewCount;
    } else {
      this.countToSync--;
      this.itemsToSync -= product.NewCount;
    }
    this.syncAll = this.countToSync > 0 && this.filteredItems.length === this.countToSync;
    this.countItemsToSync();
  }

  confirmToUpdate() {
    this.isSync = true;
    this.confirmModalService.open('Confirm', `Please confirm updating ${this.countToSync} item(s).`, this.updateInventory.bind(this));
  }

  updateInventory() {
    const data = {
      StoreHash: null,
      MakeProductsVisible: this.makeVisible,
      Visible: this.isVisible,
      Inventory: []
    };

    const inventoryItems = this.filteredItems.concat(this.inventoryStorage.inventoryMatch);

    if (inventoryItems.length < 20001) {
      data.Inventory = this.colorFilter.transform(inventoryItems, this.line).
      map(product => {
        return {
          InventoryItemId: product.InventoryItemId,
          ProductId: product.ProductId,
          VariantId: product.VariantId,
          Sku: product.Sku,
          Title: product.Title,
          IsSynchronized: product.IsSynchronized,
          QuantityBefore: product.CurrentCount,
          QuantityAfter: product.NewCount,
          CurrentCount: product.CurrentCount,
          NewCount: product.NewCount,
          Selected: product.sync,
          RfidCount: product.RfidCount,
          OrdersCount: product.OrdersCount
        };
      });

      this.progressBarData.operationType = ActivityOperationType.SyncInventory;
      this.isSyncInProgress = true;
      setTimeout(() => this.startProgressBarSync(), 500)
      this.progressBarData.isWasStarted = true;
      this.progressBarData.isSync = true;

    this.inventorySyncService.enqueuedUpdateItem(data, this.data.currentLocationExternalId, urls.enqueuedUpdateItemUrl).subscribe(res => {
      this.progressBarData.isSync = false;
    },
      this.errorHandle('updating the inventory'));
    } else {
      this.errorModalService.open('Warning', 'updating the inventory. Inventory(Mismatch + Match) must be no more than 20,000 products.');
    }
  }

  private filterByItem(item) {
    this.items = this.items.filter(function (i) {
      return ('' + i.ProductId + i.VariantId) != ('' + item.ProductId + item.VariantId);
    });
    this.inventoryStorage.inventorySync = this.items;
    this.inventoryStorage.SetMismatchItemsCount();
    this.inventoryStorage.SetInventoryItemsCounts(this.items);
  }

  private errorHandle(message: string) {
    return err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open('Error', message);
    }
  };

  private redirectToConfirmationFulfilSync() {
    window.open('https://' + this.data.shopName + '.fulfil.io/client/#/model/stock.inventory?filter=' + encodeURIComponent(this.fulfilSyncConfirmationIds), '_blank');
  }


  openEditor(product) {
    product.editable = true;
    this.filteredItems.forEach(element => {
      if (product.VariantId && product.VariantId !== element.VariantId) {
        element.editable = false;
      } else if (product.ProductId !== element.ProductId) {
        element.editable = false;
      }
    });
  }

  sortByAvailable() {
    if (this.isSortedByAvailableAscending == 'default' || this.isSortedByAvailableAscending == 'desc') {
      this.filteredItems = this.filteredItems.sort((a, b) => this.availableSort(a, b));
      this.isSortedByAvailableAscending = 'asc';
    } else if (this.isSortedByAvailableAscending == 'asc') {
      this.filteredItems = this.filteredItems.sort((a, b) => this.availableSort(b, a));
      this.isSortedByAvailableAscending = 'desc';
    }
  }

  sortByPartner() {
    if (this.isSortedByPatnerAscending == 'default' || this.isSortedByPatnerAscending == 'desc') {
      this.filteredItems = this.filteredItems.sort((a, b) => this.partnerSort(a, b));
      this.isSortedByPatnerAscending = 'asc';
    } else if (this.isSortedByPatnerAscending == 'asc') {
      this.filteredItems = this.filteredItems.sort((a, b) => this.partnerSort(b, a));
      this.isSortedByPatnerAscending = 'desc';
    }
  }

  private availableSort(a, b) {
    return a.NewCount - b.NewCount;
  }

  private partnerSort(a, b) {
    return a.CurrentCount - b.CurrentCount;
  }

  print() {
    this.loading = true;
    this.showContent = false;
    this.buildCopyProducts().pipe(
      switchMap(importedProducts => {
        const importedProductsDic = importedProducts.reduce<{[key: string]: ProductCopyModelResponse}>((acc, cur) => {
          acc[cur.PartnerProductId + cur.PartnerVariandId] = cur;
          return acc;
        }, {});
        
        const productsToPrint = this.filteredItems.map(p => {
          return [{ ProductId: importedProductsDic['' + p.ProductId + p.VariantId]?.ProductId, Quantity: p.printQuantity, Name: p.Title, Sku: p.Sku }];
        })
        .flat(1)
        .filter(_ => !!_.ProductId && _.Quantity > 0)
        .map<SearchProductModel>(_ => {
          return {
            Name: _.Name,
            ProductId: _.ProductId,
            Quantity: _.Quantity,
            Sku: _.Sku,
          }
        });

        return this.PrintingService.printProducts(this.data, productsToPrint);
      })
    ).subscribe(res => {
      this.PrintingService.goToPrintTab();
    },
    err => {
      this.loading = false;
      this.errorModalService.open('Error', 'print product(s)');
    });
  }

  removePrintedItems(): void {
    this.filteredItems = this.filteredItems.filter(p => {
      return !p.sync;
    });
    this.loading = false;
  }

  getXmlCsvSpreadsheet(type: string): void {
    const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
    let blob: Blob = null;
    const link = document.createElement('a');
    const bookName = 'Inventory Spreadsheet';

    link.download = bookName + ' ' + date;

    const items = [];

    const platform = this.data.platform;

    const spreadsheetItems = this.filteredItems.filter(it => it.sync);
    spreadsheetItems.forEach((i) => {
      const item: any = {};
      item.Title = i.Title;
      item.Sku = i.Sku;
      item.RFID = i.RfidCount;
      if (this.data.isBigCommerce) {
        item.Bpn = i.Bpn;
        item.Orders = i.OrdersCount;
        item.Available = i.NewCount;
        item.BigCommerce = i.CurrentCount;
      } else if (this.data.isShopify) {
        item.Orders = i.OrdersCount;
        item.Available = i.NewCount;
        item.Shopify = i.CurrentCount;
      } else if (this.data.isVend) {
        item.Available = i.NewCount;
        item.Vend = i.CurrentCount;
      } else if (this.data.isFulfil) {
        item.Fulfil = i.CurrentCount;
      }

      items.push(item);
    });

    const binaryWS = XLSX.utils.json_to_sheet(items);

    if (type == 'xlsx') {
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, binaryWS, bookName);

      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      blob = new Blob([this.s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' })
    } else if (type = 'csv') {
      const csv = XLSX.utils.sheet_to_csv(binaryWS);
      blob = new Blob([this.s2ab(csv)]);
      link.download = `${bookName + ' ' + date}.csv`;
    }

    this.closePopup();

    if (blob == null) {
      this.errorModalService.open('Error', 'Unresolved export type');
      return;
    }

    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }
    return buf;
  }

  closePopup() {
    this.isShowSpreadsheetPopup = false;
  }

  openPopup() {
    if (this.filteredItems.filter(i => i.sync).length > 0) {
      this.isShowSpreadsheetPopup = true;
    }
  }

  openPrintModal() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      const items = this.filteredItems.filter(p => {
        return p.sync && p.CurrentCount - p.NewCount > 0;
      });
      if (items.length > 0) {
        const modalRef = this.modalService.open(InventoryPrintModalComponent, this.ngbModalOptions);
        modalRef.componentInstance.items = items;
        modalRef.componentInstance.passEntry.subscribe((r) => {
          this.print();
        });
      }
    } else {
      this.print();
    }
  }

  toggleSelected(index, event) {
    if (event.shiftKey) {
      if (this.lastSelected < 0) {
        this.lastSelected = index;
      } else {
        const min = this.lastSelected > index ? Math.min(index, this.lastSelected) + 1 : Math.min(index, this.lastSelected);
        const max = Math.max(index, this.lastSelected);
        const checked = this.filteredItems[this.lastSelected].sync;
        this.filteredItems
          .slice(min, max)
          .forEach(item => item.sync = checked);
        this.lastSelected = -1;
      }
    } else {
      this.lastSelected = -1;
    }
  }

  openOrdersInfoModal(product: any) {
    if (product.OrdersCount > 0 && !this.data.isVend) {
      const lastAuditDate = this.inventoryStorage.lastAuditDate ? new Date(this.inventoryStorage.lastAuditDate) : new Date();
      const lastAuditDateFormatted = lastAuditDate.toLocaleTimeString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
      product.Orders.forEach(o => {
        o.link = this.urlBuilder.getUrltoOrder(o.Id, this.data, OrderTypes.Sales);
        o.created_at = new Date(o.created_at).toLocaleTimeString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        if (this.data.isShopify) {
          o.fulfillment_status = o.fulfillment_status ? o.fulfillment_status : 'Unfulfilled'; // != "fulfilled"? "/Unfulfilled": "/Fulfilled";
          o.status = o.closed_at ? 'Closed/' : o.cancelled_at ? 'Canceled/' : 'Open/';
        } else if (this.data.isBigCommerce) {
          o.status = '';
        }
      })
      const modalRef = this.modalService.open(InventoryOrderModalComponent, this.ngbModalOptions);
      modalRef.componentInstance.product = product;
      modalRef.componentInstance.orders = product.Orders;
      modalRef.componentInstance.date = lastAuditDateFormatted;
      modalRef.componentInstance.data = this.data;
    }
  }

  getLoadResult() {
    this.inventoryStorage.Load(this.progressBarLoadData.hangfireJobId, this.data.currentLocationExternalId, ActivityOperationType.LoadInventory);
    this.getMismatchInventory();
  }

  getSyncResult() {
    this.hangFireService.getHangFireJobResult(this.progressBarData.hangfireJobId, this.data.currentLocationExternalId, ActivityOperationType.SyncInventory).subscribe(res => {
      if (this.data.isFulfil) {
        this.fulfilSyncConfirmationIds = res.FulfilSyncConfirmationIds;
        if (this.fulfilSyncConfirmationIds != null) {
          this.loading = false;
          this.confirmModalService.open('Confirm', 'Inventory adjustment request has been created. Please confirm in Fulfil (separate tab).', this.redirectToConfirmationFulfilSync.bind(this));
        };
        this.getInventory();
      } else {
        const syncItems = this.filteredItems.filter(product => product.sync);
          this.isSyncInProgress = false;
          this.progressBarData.isSync = false;
          this.progressBarData.isTaskInProgress = false;
          const arrMatch = [];
          syncItems.forEach(i => {
            i.CurrentCount = i.NewCount;
            i.FilterType = 'Default';
            i.sync = false;
            this.filterByItem(i);
            if (i.RfidCount > 0) {
              arrMatch.push(i);
            }
          });
          this.inventoryStorage.inventoryMatch = arrMatch.concat(this.inventoryStorage.inventoryMatch);
          this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryMatch.length;
      }
      this.lineFilter(this.line);
    });
  }

  private startProgressBarLoad() {
    this.progressBarLoadData.isTaskInProgress = true;
  }

  private startProgressBarSync() {
    this.progressBarData.isTaskInProgress = true;
  }

  private buildCopyProducts(): Observable<ProductCopyModelResponse[]> {
    const productsToSave = this.buildProductsToSaveList();
    return this.productsService.copyProducts(productsToSave);
  }

  buildProductsToSaveList(): ProductCopyModelRequest[] {
    const productsToSave: ProductCopyModelRequest[] = this.filteredItems
      .filter(p => p.sync && p.CurrentCount > 0)
      .map(p => {
        return {
          ProductId: p.ProductId,
          VariantIds: [ p.VariantId ]
        };
      });

    return productsToSave;
  }
}

