import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from 'app/models/date/enum';
import moment from 'moment';

@Pipe({
    name: 'localDate',
    standalone: false
})
export class LocalDatePipe implements PipeTransform {

    format: string;

    constructor(@Inject(LOCALE_ID) private locale: string){
        this.format = DateFormat[this.locale] ? DateFormat[this.locale] : DateFormat['en-US'];
        this.locale = this.format == DateFormat['en-US'] ? 'en-US' : this.locale;
    }

	transform(date: Date | string, format: string = this.format): string | null {
		if (date) {
			const localeDate = moment.utc(date).local().toDate();
			return new DatePipe(this.locale).transform(localeDate, format);
		}
		return null;
	}
}