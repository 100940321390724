import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-fulfil-login',
    templateUrl: './fulfil-login.component.html',
    styleUrls: ['./fulfil-login.component.css'],
    standalone: false
})
export class FulfilLoginComponent implements OnInit {
  clientIdentity: string;
  @ViewChild('content', { static: true }) content: ElementRef;
  imageUrl: string;
  imageBlockClass: string;
  imageClass: string;
  titleClass: string;
  buttonClass: string;
  data: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.imageUrl = "./assets/images/full-transparent-white.png";
        this.imageBlockClass = "fulfil-icon-block";
        this.imageClass = "fulfil-icon";
        this.titleClass = "form-group";
        this.buttonClass = "fulfil-btn";
        this.clientIdentity = localStorage.getItem("fulfilIdentity");

        let ngbModalOptions: NgbModalOptions = {
          backdrop : 'static',
          keyboard : false,
          centered: true
        };
    
        this.modalService.open(this.content, ngbModalOptions);
  }

  login() {
    localStorage.setItem("fulfilIdentity", this.clientIdentity);
    window.location.href = `https://${this.clientIdentity}.fulfil.io/oauth/authorize?response_type=code&scope=group:group_stock&client_id=${environment.fulfilClientId}&redirect_uri=${environment.fulfilRedirectUrl + "?identity=" + this.clientIdentity}`
  }

}