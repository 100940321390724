import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mapping',
    templateUrl: './mapping.component.html',
    styleUrls: ['./mapping.component.css'],
    standalone: false
})
export class MappingComponent implements OnInit {
  saveResult = '';
  saving = false;

  constructor() { }

  ngOnInit() {
  }

  saved($event) {
    if ($event === true) {
      this.saveResult = 'Mapping saved!';
      setTimeout(function () { this.saveResult = ''; }, 3000);
    } else {
    }

    this.saving = false;
  }
}
