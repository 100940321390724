<div class="row">
  <div *ngIf="loading" align="center"  class="col-md-12">
    <br/>
    <div class="loader"></div>
  </div>
</div>
<ng-container *ngIf="!loading">
  @if(toTopButtonVisible){
    <!--<button (click)="topFunction()" id="myBtn" title="Go to top"></button>-->
    <a class="btn btn-default" id="myBtn" (click)="topFunction()" aria-label="To top" title="Go to top">
      <i id="icon-to-top" class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
  }
  <div class=" col-md-12">
    <nav>
        <ul class="pagination justify-content-center">
            <li class="page-item cursorFinger" [ngClass]="{disabled:shopifyPage === 1}">
                <a class="page-link colorGray" (click)="getHistory(shopifyPage - 1)"><span class="fa fa-angle-left"></span></a>
            </li>
            <li class="page-item"><a class="page-link">{{shopifyPage}}</a></li>
            <li class="page-item cursorFinger" [ngClass]="{disabled: pageButtonBlock}">
                <a class="page-link colorGray" (click)="getHistory(shopifyPage + 1)"><span class="fa fa-angle-right"></span></a>
            </li>
        </ul>
    </nav>
  </div>

  <div class="row" style=" padding: 0 16px 0 16px;">
    <input #name (input)="searchTrigger$.next()" [(ngModel)] = "searchKeyword" type="text" class="form-control inputSearchByName col-md-6" placeholder="{{searchPlaceholder}}">
    <div class="col-md-5">
      <div style="height: 19px; line-height: 0px;">
        <input id="autoReceiveApply" type="checkbox" [(ngModel)]="settings.AutoReceiveApply" (ngModelChange)="updateCheckbox()"/>
        <label for="autoReceiveApply" class="ml-5">AutoReceiveApply</label>
      </div>
      <div style="height: 19px;">
        <input id="autoTransferApply" type="checkbox" [(ngModel)]="settings.AutoTransferApply" (ngModelChange)="updateCheckbox()"/>
        <label for="autoTransferApply" class="ml-5">AutoTransferApply</label>
      </div>
    </div>
    <button class="btn btn-info button-refresh copyAndRefreshButton simpleRfIdColor cursorFinger col-md-1" (click)="refresh()" title="Refresh" >
      <span class="fa fa-sync-alt button-icon"></span>
    </button>
  </div>
  <div class="row" style="margin-top: 5px;">
    <div class="col-md-12">
      @if(loadingInBody){
        <div *ngIf="loadingInBody" align="center">
          <br/>
          <div class="loader"></div>
        </div>
      } @else {
        <table class="table">
          <tbody *ngFor="let entry of sortedHistoryItems" class="auto-sync-table-bordered">
            <tr>
              <td style="width: 50px;">
                <strong>{{getOperationType(entry)}}</strong>
              </td>
              <td colspan="5" align="center">
                <strong>{{ entry.key | localDate }}</strong>
              </td>
              <td style="text-align: end;">
                @if(showApplySessionButton(entry)){
                  <button type="submit" style="width: 50px;" class="btn btn-info btn-sm" (click)="applyChangesSession(entry?.value)">Apply</button>
                }
              </td>
            </tr>
        
            <ng-container *ngFor="let operation of entry?.value">
              <tr>
                <td style="text-align: start;">
                  <app-image-hendler [product]="operation"></app-image-hendler>
                </td>
                <td  colspan="4">{{operation.ProductTitle}}</td>
                <td style="text-align: center;">{{operation.Status}}</td>
                <td style="text-align: end;">
                  @if(showApplyButton(operation)){
                    <button type="submit" style="width: 50px;" class="btn btn-info btn-sm" (click)="applyChanges(operation)">Apply</button>
                  }
                </td>
              </tr>
              <ng-container *ngFor="let detail of operation?.Details let ind = index">
                <tr>
                  <td></td>
                  <td style="text-align: end;">{{ind + 1}}.</td>
                  <td>{{detail.LocationName}} </td>
                  <td style="text-align: center;">{{detail.Adjustment}} </td>
                  <td style="text-align: center;">{{detail.NewOnHandQuantity}} </td>
                  <td></td>
                  <td></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      }
    </div>
  </div>

  @if(toTopButtonVisible){
    <div class=" col-md-12">
      <nav>
          <ul class="pagination justify-content-center">
              <li class="page-item cursorFinger" [ngClass]="{disabled:shopifyPage === 1}">
                  <a class="page-link colorGray" (click)="getHistory(shopifyPage - 1)"><span class="fa fa-angle-left"></span></a>
              </li>
              <li class="page-item"><a class="page-link">{{shopifyPage}}</a></li>
              <li class="page-item cursorFinger" [ngClass]="{disabled: pageButtonBlock}">
                  <a class="page-link colorGray" (click)="getHistory(shopifyPage + 1)"><span class="fa fa-angle-right"></span></a>
              </li>
          </ul>
      </nav>
    </div>
  }
</ng-container>