import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NavigationService } from 'app/services';

@Component({
    selector: 'app-callback',
    templateUrl: './signout-callback.component.html',
    styleUrls: ['./signout-callback.component.css'],
    standalone: false
})
export class SignoutCallbackComponent {
	constructor(public oidcSecurityService: OidcSecurityService, private navigationService: NavigationService) {}

	ngOnInit(): void {
		console.log('access token is ' + this.oidcSecurityService.getAccessToken());
		this.navigationService.toDefaultPage();
	}
}
