import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-decline-charge',
    templateUrl: './decline-charge.component.html',
    styleUrls: ['./decline-charge.component.css'],
    standalone: false
})
export class DeclineChargeComponent implements OnInit {
  
  newChargeUrl = '';
  declineUrl = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {     
    this.newChargeUrl = activatedRoute.snapshot.queryParams['newChargeUrl'];
    this.declineUrl = activatedRoute.snapshot.queryParams['declineUrl'];
  }

  ngOnInit() {
  }

  decline(){
    window.top.location.href =  decodeURI(this.declineUrl);
  }

  back(){
    window.top.location.href = decodeURI(this.newChargeUrl);
  }

}
