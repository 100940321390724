import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'notify-modal-component',
    template: `
    <div class="modal-header center block">
      <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body center">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer center block">
      <button type="button" class="btn btn-outline-dark blue-btn" (click)="activeModal.close(true)">{{confirmButton}}</button>
      <button *ngIf="isConfirmation" type="button" class="btn btn-outline-dark blue-btn" (click)="activeModal.close(false)">{{cancelButton}}</button>
    </div>
  `,
    styleUrls: ['./notify-modal.component.css'],
    standalone: false
})

export class NotifyModalComponent {
  @Input() title;
  @Input() message;
  @Input() isConfirmation;
  @Input() confirmButton;
  @Input() cancelButton;

  constructor(public activeModal: NgbActiveModal) {}
}