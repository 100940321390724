<br/>

<div class="row">
  <div *ngIf="loading" align="center"  class="col-md-12">
    <br/>
    <div class="loader"></div>
  </div>
</div>
<div [hidden]="loading || !isUploadProducts">
  <h3 align="center">{{progressBarTitle}}</h3>
  <app-progress-bar [data]='progressBarData' (onFinish)="finishUpload()" (onProgress)="startProgress()"></app-progress-bar>
</div>
<ng-container *ngIf="!loading && !isUploadProducts">
  <br/>
  <div class="row">
    @if(!viewSmallPager) {
      <div class=" col-md-12">
        <nav>
            <ul  class="pagination" *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">
                <li class="page-item cursorFinger" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link cursorFinger" (click)="getProducts(1)">First</a>
                </li>
                <li class="page-item cursorFinger" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link colorGray" (click)="getProducts(pager.currentPage - 1)"><span class="fa fa-angle-left"></span></a>
                </li>
                <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage === page}">
                    <a class="page-link cursorFinger" (click)="getProducts(page)">{{page}}</a>
                </li>
                <li class="page-item cursorFinger" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link colorGray" (click)="getProducts(pager.currentPage + 1)"><span class="fa fa-angle-right"></span></a>
                </li>
                <li class="page-item cursorFinger" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="getProducts(pager.totalPages)">Last</a>
                </li>
            </ul>
        </nav>
      </div>
    } @else if(viewSmallPager) {
      <div class=" col-md-12">
        <nav>
            <ul class="pagination" class="pagination justify-content-center">
                <li class="page-item cursorFinger" [ngClass]="{disabled:fulfilPage === 1}">
                    <a class="page-link colorGray" (click)="getProducts(fulfilPage - 1)"><span class="fa fa-angle-left"></span></a>
                </li>
                <li class="page-item"><a class="page-link">{{fulfilPage}}</a></li>
                <li class="page-item cursorFinger" [ngClass]="{disabled:(data.isShopify && nextPageLink == null) || lightspeedButtonBlock}">
                    <a class="page-link colorGray" (click)="getProducts(fulfilPage + 1)"><span class="fa fa-angle-right"></span></a>
                </li>
            </ul>
        </nav>
      </div>
    }
  </div>
  <div class="controlPanel row">
        <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="copyProducts()" 
          [disabled]="countOfCheckedProducts === 0" >{{countOfCheckedProducts}} Import to <img src="./assets/images/circle-logo-small-w.svg">
        </button>
        <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="print()" 
          [disabled]="!isPrintingEnable" [ngbTooltip]="isPrintingEnableTitle" 
          [openDelay]="300" [closeDelay]="300">
          {{countToPrint}} to print
        </button>
        <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="copyUpdateProducts()" >Import All</button>
        <button class="btn btn-info button-refresh copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="refresh()" >
          <span class="fa fa-sync-alt button-icon"></span>
        </button>
        <input #name (input)="getProducts(1)" [(ngModel)] = "searchKeyword" type="text" class="form-control inputSearchByName" placeholder="{{searchPlaceholder}}">
        <select *ngIf="!data.isFulfil" [(ngModel)]="isVisible" class="form-control cursorFinger col filterDropdown" (change)="getProducts(1)">
                <option value="">All</option>
                <option value="true">Visible</option>
                <option value="false">Hidden</option>
            </select>
  </div>  
  <!-- <div *ngIf="data.isShopify && !data.UseBarcodeAsProductKey" class="modifyButton">
    <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="confirmToModifyAll(true)" >
      <span *ngIf="countOfCheckedProductsSku === 0">Modify All {{productKey}}</span>
      <span *ngIf="countOfCheckedProductsSku > 0">Modify {{countOfCheckedProductsSku}} {{productKey}}</span>
    </button>
    <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" (click)="confirmToModifyAll(false)" [disabled]="!CanRevert">
      Revert Modify {{productKey}}
    </button>
  </div> -->
  <div class="row">
    <div class="col-md-12">
      <table class="table text-center productsTable">
        <thead class="simpleRfIdColor">
          <tr class="text-center">
            <th class="imported-cell"></th>
            <th class="text-center"><input type="checkbox" class="cursorFinger" [(ngModel)]="allSelected" (change)="selectAll()"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center">Image</th>
            <th class="text-left" >Name</th>
            <!-- <th [hidden]="data.UseBarcodeAsProductKey" class="text-center"><input type="checkbox" class="cursorFinger" 
              [(ngModel)]="allSelectedSku" (change)="selectAllSku()">
            </th> -->
            <th class="text-left" >{{productKey}}</th>
            <th class="text-right" >Price</th>
            <th class="text-right" >Quantity</th>
          </tr>
        </thead>
        <tbody *ngFor="let product of products let i = index">
          <tr [ngClass]="{'childColor' : iId.checked}">
            <td class="imported-cell">
              <img *ngIf="product.IsImported && !product.Duplicate" src="./assets/images/circle-logo-small.svg">
              <img *ngIf="product.Duplicate && data.isShopify" [title]="duplicateTitle" 
                src="./assets/images/bol-failure.png">
            </td>
            <td style="padding-bottom: 0.05em;" scope="row"><input class="cursorFinger" *ngIf="product.Variants.length > 0 || product.Sku !== ''" type="checkbox" (click)="toggleSelected(i, $event)"
              [hidden] = "product.Duplicate && data.isShopify" [(ngModel)] ="product.Selected" (change)="selectAllVariantsInProduct(product)" >
            </td>
            <td class="center"><input id="{{product.Id}}" #iId (change)="0" type="checkbox" hidden>
              <label for="{{product.Id}}"><span class="fa cursorFinger" [ngClass]="{'fa-angle-right' : !iId.checked, 'fa-angle-down' : iId.checked}" 
                *ngIf="product.Variants.length > 0" ></span></label>
            </td>
            <td class="center"><input id="{{product.Id}}_vis"  [(ngModel)]="product.IsVisible" (change)="setVisibility(product)" type="checkbox" 
              hidden disabled = {{!product.IsVisibleActiv}}>
              <label for="{{product.Id}}_vis"><span class="fa cursorFinger" [ngClass]="{'fa-eye-slash' : !product.IsVisible, 'fa-eye' : product.IsVisible}"></span></label>
            </td>
            <td>
                <app-image-hendler [product]="product"></app-image-hendler>
            </td>
            <td class="text-left">{{product.Name}}</td>
            <!-- <td scope="row" [hidden]="data.UseBarcodeAsProductKey"><input class="cursorFinger" 
              *ngIf="product.Variants.length > 0 || product.Sku !== ''" type="checkbox" 
              [(ngModel)] ="product.SelectedSku" (change)="selectAllSkuVariantsInProduct(product)" (click)="toggleSelectedSku(i, $event)">
            </td> -->
            <td [hidden]="data.UseBarcodeAsProductKey" class="text-left" *ngIf="product.Variants.length > 0 || product.Sku !== '' ">
              <a href="{{getUrlToProduct(product.Id)}}" target="_blank">{{product.Sku}}</a>
            </td>
            <td [hidden]="data.UseBarcodeAsProductKey" class="text-left" *ngIf="product.Sku === '' && product.Variants.length === 0">
              <button class="btn btn-sm btn-info simpleRfIdColor cursorFinger" (click)="getUrl(product.Id)" href=''>Add {{productKey}}</button>
            </td>
            <td [hidden]="!data.UseBarcodeAsProductKey" class="text-left" *ngIf="product.Variants.length > 0 || product.Barcode !== '' ">
              <a href="{{getUrlToProduct(product.Id)}}" target="_blank">{{product.Barcode}}</a>
            </td>
            <td [hidden]="!data.UseBarcodeAsProductKey" class="text-left" *ngIf="product.Barcode === '' && product.Variants.length === 0">
              <button class="btn btn-sm btn-info simpleRfIdColor cursorFinger" (click)="getUrl(product.Id)" href=''>Add {{productKey}}</button>
            </td>
            <td class="text-right"><div *ngIf="product.Price !== null">{{product.Price}}</div></td>
            <td class="text-right" *ngIf="product.IsInventoryTrackingProduct">{{product.Quantity}}</td>
            <td class="text-right" *ngIf="!product.IsInventoryTrackingProduct">
              <span class="fa fa-map-marker-alt cursorFinger" (click)="setInventoryTracking(product)"></span>
            </td>                    
          </tr>
          <tr [hidden]="!iId.checked" class="bgColorGrey">
            <td [ngClass]="{'childColor' : iId.checked}"></td>
            <td class="imported-cell"></td>
            <td class="text-center">Image</td>
            <td class="boldText text-left">Name</td>
            <td class="boldText text-left" colspan="2">Variants</td>                
            <td class="boldText text-left">{{productKey}}</td>
            <td class="boldText text-right" colspan="2">Price</td>
            <td class="boldText text-right" colspan="2">Quantity</td>
          </tr>
          <tr *ngFor="let variant of product.Variants" [hidden]="!iId.checked" [ngClass]="{'childColor' : iId.checked}">
            <td class="imported-cell">
              <img *ngIf="variant.IsImported && !variant.Duplicate" src="favicon.ico" style="float: right">
              <img *ngIf="variant.Duplicate && data.isShopify" [title]="duplicateTitle"
              src="./assets/images/bol-failure.png" style="float: right">
            </td>
            <td><input type="checkbox" [hidden] = "variant.Duplicate && data.isShopify" [(ngModel)]="variant.Selected"  
              (change)="chekSelectedVariantsInProduct(product)" *ngIf="variant.Sku !== ''">
            </td>
            <td class="text-center">
              <app-image-hendler [product]="variant"></app-image-hendler>
            </td>
            <td class="text-left">{{product.Name}}</td>
            <td class="text-left" colspan="2">{{variant.Properties}}</td>
            <td [hidden]="data.UseBarcodeAsProductKey" class="text-left" *ngIf="variant.Sku !== ''">
              <a href="{{getUrlToProduct(product.Id)}}" target="_blank">{{variant.Sku}}</a>
            </td>
            <td [hidden]="data.UseBarcodeAsProductKey" class="text-left" *ngIf="variant.Sku === ''">
              <button class="btn btn-sm btn-info simpleRfIdColor" (click)="getUrl(product.Id)" href=''>Add {{productKey}}</button>
            </td>
            <td [hidden]="!data.UseBarcodeAsProductKey" class="text-left" *ngIf="variant.Barcode !== ''">
              <a href="{{getUrlToProduct(product.Id)}}" target="_blank">{{variant.Barcode}}</a>
            </td>
            <td [hidden]="!data.UseBarcodeAsProductKey" class="text-left" *ngIf="variant.Barcode === ''">
              <button class="btn btn-sm btn-info simpleRfIdColor" (click)="getUrl(product.Id)" href=''>Add {{productKey}}</button>
            </td>
            <!-- <td [hidden]="data.UseBarcodeAsProductKey" class="checked-sku"><input type="checkbox" [(ngModel)]="variant.SelectedSku"  
              (change)="chekSelectedSkuVariantsInProduct(product)" *ngIf="variant.Sku !== ''">
            </td> -->
            <td class="text-right" colspan="2">{{variant.Price}}</td>
            <td class="text-right" colspan="2" *ngIf="variant.IsInventoryTrackingVariant">{{variant.Quantity}}</td>
            <td class="text-right" colspan="2" *ngIf="!variant.IsInventoryTrackingVariant"><span class="fa fa-map-marker-alt cursorFinger" 
              (click)="setInventoryTracking(product, variant.Id)"></span></td>
          </tr>
        </tbody>
      </table>
      <hr>
    </div>
  </div>
</ng-container>



