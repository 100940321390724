import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BaseNotificationService } from 'app/core/abstractions/base-notification-service';
import { State } from 'app/enums/printer-state';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PrinterNotificationService extends BaseNotificationService {
	private readonly stateChangedEventName = 'StateChanged';
	private readonly registeredEventName = 'Registered';
	private readonly deletedEventName = 'Deleted';

	constructor(oidcSecurityService: OidcSecurityService) {
		super(environment.printServiceBaseAddress + '/signal/v1/printing/printers', oidcSecurityService);
	}

	public addPrinterStateChangedListener = (
		callBackOwner: Object,
		callback: (clientId: string, state: State, serialNumber?: string, issueDescription?: string) => void,
	) => {
		this.addListener(
			callBackOwner,
			this.stateChangedEventName,
			(model: PrinterStateChanged) =>
				callback(model.clientId, model.state, model.serialNumber, model.issueDescription),
		);
	};

	public addPrinterRegisteredListener = (
		callBackOwner: Object,
		callback: (clientId: string, serialNumber: string) => void,
	) => {
		this.addListener(callBackOwner, this.registeredEventName, (clientId: string, serialNumber: string) =>
			callback(clientId, serialNumber),
		);
	};

	public addPrinterDeletedListener = (
		callBackOwner: Object,
		callback: (clientId: string, serialNumber: string) => void,
	) => {
		this.addListener(callBackOwner, this.deletedEventName, (clientId: string, serialNumber: string) =>
			callback(clientId, serialNumber),
		);
	};

	public removePrintedChangedListener () {
		this.removeChangedListener(this.stateChangedEventName);
	};
}
interface PrinterStateChanged {
	clientId: string;
	state: State;
	serialNumber?: string;
	issueDescription?: string;
}