<div class="row">
  <div *ngIf="loading" align="center"  class="col-md-12">
    <br/>
    <div class="loader"></div>
  </div>
</div>
<div [hidden]="!isRevertInProgress">
  <h3 align="center">Revert Inventory</h3>
  <app-progress-bar [data]='progressBarData' (onFinish)="finishRevert()"></app-progress-bar>
</div>
<ng-container *ngIf="!loading && !isRevertInProgress">
  <div class="row">
    <div class="col-md-1">
      <button (click)="inventorySyncTab()" style="background-color: rgb(84, 143, 214);" class="btn btn-info">Back</button>
    </div>
      <div class="justify-content-center col-md-11" *ngIf="pages.length > 1">
          <nav style="margin-left: 30%">
          <ul class="pagination">
              <li *ngFor="let page of pages" class="page-item cursor-pointer" >
                  <a class="page-link" (click)="getReports(page)" [ngClass]="{active: page === selectedPage}">{{page}}</a>
              </li>
          </ul>
          </nav>
        </div>
  </div>
    
  <div class="row" style="margin-top: 5px">
    <div class="col-md-12">
      <table class="table syncHistory">
          <thead>
          <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Report</th>
              <th>Count</th>
              <th></th>
          </tr>
          </thead>
          <tbody *ngFor="let item of historyItems let i = index">
            <tr ng-class="item.class">
              <td class="left">{{item.Date | localDate}}</td>
              <td *ngIf="item.IsRevertReport">Reverted</td>
              <td *ngIf="!item.IsRevertReport">Synchronized</td>
              <td>
                <span *ngIf="item.Link" class="link-download" (click)=getSyncReport(item)>Download</span>
                <span *ngIf="!item.Link" style="color: darkgrey;">Download</span>
              </td>
              <td>{{item.Count}}</td>
              <td class="center">
                <button *ngIf="checkAccessRevert(item)" 
                  type="submit" class="btn btn-info btn-sm" (click)="confirmToRevert(item)">Revert</button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
  </div>
</ng-container>
