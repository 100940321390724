import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequiredChildsData } from 'app/core/required-childs-data';

@Component({
    selector: 'product-print-modal-component',
    template: `
    <div class="modal-header">
        <!--<h4 *ngIf="!isInfo" class="modal-title">Choose products to print!</h4>-->
        <h4 class="modal-title">Order: {{order.Name}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-scrollable-container">
            <div *ngFor="let product of order.Items; index as i" class="product-row">
                <div class="container">
                    <div class="box-1">
                        <app-image-hendler [product]="product"></app-image-hendler>
                    </div>
                    <div class="box-2">
                    @if(data.UseBarcodeAsProductKey){
                        <span>Barcode:</span>
                    } @else {
                        <span>Sku:</span>
                     }
                    </div>
                    <div class="box-3">
                    @if(data.UseBarcodeAsProductKey){
                        <span>{{product.Barcode}}</span>
                    } @else {
                        <span>{{product.Sku}}</span>
                     }
                    </div>
                    <div class="box-4">
                        <span>Quantity:</span>  
                    </div>
                    <div class="box-5">
                        <span>{{product.Quantity}}</span>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info cursor-pointer-btn" (click)="activeModal.close(false)">Close</button>
    </div>
    `,
    styleUrls: ['./product-print-modal.component.css'],
  })

  export class ProductPrintModalComponent{
    @Input() order;
    @Input() data: RequiredChildsData;

    constructor(public activeModal: NgbActiveModal) {}


  }