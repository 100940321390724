import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: false
})

@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: Array<string>, value: string): any[] {
    if (!items) {
      return [];
    }

    if (!field || !value) {
      return items;
    }

    const matchedItems = items.filter(singleItem => {

      let isMatchingField = false;

      field.forEach(element => {
        isMatchingField = singleItem[element].toLowerCase().includes(value.toLowerCase()) || isMatchingField;
      });

      return isMatchingField;
    });

    return matchedItems;
  }
}