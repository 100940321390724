import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { UrlBuilderService } from 'app/core/url-builder';
import { PrintingService } from 'app/printing/printing.service';
import { RequiredChildsData } from './../core/required-childs-data';

@Component({
  selector: 'partial-print-modal-component',
  template: `
    <div class="modal-header">
        <h4 *ngIf="!isInfo" class="modal-title">Choose products to print!</h4>
        <h4 *ngIf="isInfo" class="modal-title">Order number: {{order.Number}}, Date: {{order.CreatedAt | date:'mediumDate':'':data.locale}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container modal-scrollable-container">
            <div *ngFor="let product of products; index as i" class="product-row">
                <div>
                    <div class="col-md-2 inline">
                      <app-image-hendler [product]="product"></app-image-hendler>
                    </div>
                    <div class="col-md-6 inline product-title">
                        <span>{{product.Sku}} | {{product.Name}}</span>
                    </div>
                    <div class="col-md-1 inline">
                        <span>{{product.MaxQuantity}}</span>
                    </div>
                    <div *ngIf="!isInfo" class="inline col-md-2">
                        <input type="text"
                            [ngClass]="{ 'error': product.Quantity < 0 || product.error == 'NaN' || product.error == 'Empty' }"
                            class="form-control inline" [(ngModel)]="product.Quantity" (ngModelChange)="quantityOnChange(product)">
                    </div>
                </div>
                    <div class="col-md-6 inline"></div>
                    <div class="col-md-5 inline right">
                        <span *ngIf="product.Quantity < 0" class="error-message">Quantity should be greater then 0.</span>
                        <span *ngIf="product.error == 'Empty'" class="error-message">This field shouldn't be empty.</span>
                        <span *ngIf="product.error == 'NaN'" class="error-message">This field should be number.</span>
                    </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isInfo" class="modal-footer">
        <button type="button" class="btn btn-outline-dark" [attr.disabled]="!isValid ? '' : null" (click)="print()">Print</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Cancel</button>
    </div>
    <div *ngIf="isInfo" class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Ok</button>
    </div>
  `,
  styleUrls: ['./partial-print-modal.component.css'],
})

export class PartialPrintModalComponent {
    @Input() products;
    @Input() order;
    @Input() isInfo : boolean;
    @Input() data: RequiredChildsData;
    isValid = true;

    constructor(public activeModal: NgbActiveModal, private errorModalService: ErrorModalService,
        private getUrlService: UrlBuilderService, private PrintingService: PrintingService) {}

    quantityOnChange(product) {
        if (product.Quantity == "") {
            product.isInValid = true;
            product.error = "Empty";
        } else if (!isNaN(product.Quantity)) {
            if (product.Quantity < 0) {
                product.isInValid = true;
            } else {
                product.isInValid = false;
            }
            product.error = "";
        } else {
            product.isInValid = true;
            product.error = "NaN";
        }

        this.isValid = true;
        this.products.forEach(element => {
            if (element.isInValid) {
                this.isValid = false;
            }
        });
    }

    print() {
        console.log("Print products and close modal");
        let productsQuantities = [];
        this.products.forEach(element => {
            productsQuantities.push({
                Id: element.Id,
                Quantity: element.Quantity,
            });
        });

        this.PrintingService.printOrders([this.order.Id], this.data, true, productsQuantities).subscribe(res => {
            this.activeModal.close(true);
            this.PrintingService.goToPrintTab();
        }, error => {
            this.errorModalService.open("Error", "when printing part of order.");
        });
    }
}
