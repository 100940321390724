import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';
import { environment } from 'environments/environment';
import { AutoSyncHistoryOperation, AutoSyncOperationApplyModel, AutoSyncOperationModelResponse } from './auto-sync-history.component';

@Injectable()
export class AutoSyncHistoryService extends BaseHttpService {

  getHistory( page: number, keyword: string): Observable<AutoSyncOperationModelResponse> {
    return this.get(urls.shopify.getAutoSyncHistoryUrl + "?page=" + page + '&keyword=' + encodeURIComponent(keyword));
  }

  apllyAutoSyncChanged(items:AutoSyncHistoryOperation[]): Observable<AutoSyncOperationApplyModel[]>{
    return this.post(urls.shopify.applyShopifyAutoSyncOperations, items);
  }
}