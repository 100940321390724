import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-callback',
    templateUrl: './signin-callback.component.html',
    styleUrls: ['./signin-callback.component.css'],
    standalone: false
})
export class SigninCallbackComponent implements OnInit, OnDestroy {
	private _subscription: Subscription | null = null;
	constructor(public oidcSecurityService: OidcSecurityService) {}

	ngOnInit(): void {
		this._subscription = this.oidcSecurityService.checkAuth().subscribe(res => {
			if (res.isAuthenticated) {
				//We don't need manually route anywhere, OIDC will automatic redirect to latest page
			} else {
				console.warn("Authentication isn't success");
			}
		});
	}

	ngOnDestroy(): void {
		if (!!this._subscription) {
			this._subscription.unsubscribe();
		}
	}
}
