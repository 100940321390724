import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { SignalRHelper } from '../utils/signalr.helper';

export abstract class BaseNotificationService {
	private hubConnection: signalR.HubConnection;
	private listeners = new Map<Object, { [key: string]: (...args: any[]) => void }>();
	protected readonly reconnectedEventName = 'reconnected';

	constructor(hubUrl: string, oidcSecurityService: OidcSecurityService) {
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () => firstValueFrom(oidcSecurityService.getAccessToken()),
		};

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(hubUrl, options)
			.withAutomaticReconnect(SignalRHelper.getReconnectPolicy())
			.configureLogging(environment.signalRLogLevel)
			.build();
	}

	public startConnection = () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
	};

	public removeAllListeners(callBackOwner: Object): void {
		const eventListeners = this.listeners.get(callBackOwner);
		if (eventListeners) {
			for (const eventName of Object.keys(eventListeners)) {
				this.hubConnection.off(eventName, eventListeners[eventName]);
			}
			this.listeners.delete(callBackOwner);
		}
	}

	protected addListener(callBackOwner: Object, eventName: string, callback: (...args: any[]) => void): void {
		if (!this.listeners.has(callBackOwner)) {
			this.listeners.set(callBackOwner, {});
		}

		const eventListeners = this.listeners.get(callBackOwner)!;
		eventListeners[eventName] = callback;

		this.hubConnection.on(eventName, (...args: any[]) => {
			callback(...args);
		});
	}

	public addReconnectedListener(callBackOwner: Object, callback: () => void): void {
		if (!this.listeners.has(callBackOwner)) {
			this.listeners.set(callBackOwner, {});
		}

		this.hubConnection.onreconnected(() => {
			callback();
		});
	}

	public removeChangedListener = (eventName: string) => {
		this.hubConnection.off(eventName);
	};

	public stopConnection() {
		this.hubConnection.stop()
			.then(() => console.log('Connection stoped'))
			.catch(err => console.log('Error while stoping connection: ' + err));
	}
}