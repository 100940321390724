import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-forbidden',
    templateUrl: 'forbidden.component.html',
    standalone: false
})
export class ForbiddenComponent implements OnInit {
	public isAuthenticated = false;

	constructor(private oidcSecurityService: OidcSecurityService) {}

	ngOnInit() {
		this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
			this.isAuthenticated = isAuthenticated;

			console.warn('authenticated: ', isAuthenticated);
		});
	}
}
