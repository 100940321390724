export enum DateFormat{
    'ar-AA' = 'dd/MM/yyyy h:mm:ss a',//Arabic > Arabic Countries
    'af-ZA' = 'yyyy/MM/dd HH:mm:ss',//Afrikaans > South Africa
    'ar-IN' = 'd/M/yyyy h:mm:ss a',//Arabic > India
    'ar-SA' = 'dd/MM/yyyy h:mm:ss a',//Arabic > Saudi Arabia
    'sq-AL' = 'yy-MM-dd h.mm.a',//Albanian > Albania
    'be' = 'd.M.yy HH:mm:ss',//Belarusian > Belarus
    'bn-IN' = 'd-M-yy h:mm:ss a',//Bengali > India
    'bg' = 'dd.MM.yy HH:mm:ss',//Bulgarian > Bulgaria
    'ca-ES' = 'YYYY-MM-DD HH:mm:ss',//Catalan > Spain
    'zh-CN' = 'yy-M-d ah:mm:ss',//Chinese (Simplified Han) > China
    'zh-HK' = 'yy\u5e74M\u6708d\u65e5 ah:mm:ss',//Chinese (Traditional Han) > Hong Kong S.A.R., China
    'zh-TW' = 'yyyy/M/d a h:mm:ss',//Chinese (Traditional Han) > Taiwan
    'hr-HR' = 'yyyy.MM.dd HH:mm:ss',//Croatian > Croatia
    'cs' = 'd.M.yy H:mm:ss',//Czech > Czech Republic
    'en' = 'MM/dd/yyyy hh:mm:ss a',//English > United States
    'en-AU' = 'd/MM/yy h:mm:ss a',//English > Australia
    'en-ZA' = 'yyyy/MM/dd h:mm:ss a',//English > South Africa
    'en-GB' = 'dd/MM/yyyy HH:mm:ss',//English > United Kingdom
    'en-US' = 'MM/dd/yyyy hh:mm:ss a',//English > United States	
    'fi-FI' = 'd.M.yyyy HH:mm:ss',//Finnish > Finland
    'fr-CH' = 'dd.MM.yy HH:mm:ss',//French > Switzerland
    'fr-CA' = 'yyyy/MM/dd h:mm:ss a',//French > Canada
    'fr-FR' = 'dd/MM/yyyy HH:mm:ss',//French > France
    'fr' = 'dd/MM/yyyy HH:mm:ss',//French > France
    'de-DE' = 'dd.MM.yy HH:mm:ss',//German > Germany
    'de' = 'dd.MM.yy HH:mm:ss',//German > Germany
    'it'	= 'dd/MM/yyyy HH:mm:ss',//Italian > Italy
    'he' = 'HH:mm:ss dd/MM/yy',//Hebrew > Israel
    'hi-IN' = 'd-M-yy h:mm:ss a',//Hindi > India
    'hu' = 'yyyy.MM.dd. H:mm:ss',//Hungarian > Hungary
    'es-ES' = 'dd/MM/yyyy HH:mm:ss',//Spanish > Spain
    'es' = 'dd/MM/yyyy HH:mm:ss',//Spanish > Spain
    'uk' = 'dd/MM/yyyy HH:mm:ss',//Ukrainian > Ukraine
    'ro' = 'dd/MM/yyyy HH:mm:ss',//Romanian > Romania
    'pt' = 'dd/MM/yyyy HH:mm:ss',//Portuguese > Portugal
    'pt-PT' = 'dd/MM/yyyy HH:mm:ss',//Portuguese > Portugal
    'pl' = 'dd/MM/yyyy HH:mm:ss',//Polish > Poland
    'nl-NL' = 'dd/MM/yyyy HH:mm:ss',//Dutch > Netherlands
    'nl-BE' = 'dd/MM/yyyy HH:mm:ss',//Dutch > Belgium
    'da' = 'dd/MM/yyyy HH:mm:ss',//Dutch > Dutch
    'ru' = 'dd/MM/yyyy HH:mm:ss',//Ru > Ru

}