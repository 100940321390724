import {Component, OnInit, OnDestroy, ViewEncapsulation, Input, DoCheck} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import {LayoutFormatType, State, PrintingService, SearchProductModel} from 'app/printing/printing.service';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProductModalComponent } from '../printing-modal/product-modal.component';
import { UrlBuilderService } from 'app/core/url-builder';
import { environment } from 'environments/environment';
import { RequiredChildsData } from '../core/required-childs-data';
import { IPrintJobViewModel, PrintJobNotificationService, PrintingJobStatus } from './print-job.notification.service';
import { PrintJobViewModel } from 'app/models';
import { ProductCopyModelRequest, ProductCopyModelResponse, ProductService } from 'app/products/products.service';

const nameSplitter = '; ';

declare var $: any;

@Component({
    selector: 'app-printing',
    templateUrl: './printing.component.html',
    styleUrls: ['./printing.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PrintingComponent implements OnInit, OnDestroy, DoCheck {
  private connection: any;
  private proxy: any;
  product: SearchProductModel = undefined;
  searching = false;
  searchFailed = false;
  quantity = 1;
  pages: Array<any> = [];
  selectedPage = 1;
  printJobs: Array<PrintJobViewModel> = [];
  loading = false;
  addSkuUrl: string;
  maxNameLength = 90;
  timer: any = {};
  shopName: string;
  platform: string;
  currentPage = 1;
  totalPages: number;
  isPrinterOnline: boolean;
  searchEmpty = true;

  public usePortalSource: boolean;
  public allowSourceToggle: boolean;

  public get isPrintingEnable() {
    if(!this.data.PrintingLayouts){
      return false;
    }
    const selectedLayout = this.data
      .PrintingLayouts
      .find(l => l.id == this.data.DefaultLayoutId);

      if (!selectedLayout) {
        return false;
      }

     const labelSupport = this.data.CurrentPrinter.isNewPrintClient
      || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.CurrentPrinter.isNewPrintClient)

    return this.data.isAvailablePrint && !!selectedLayout && labelSupport && !this.searchEmpty && this.isPrinterOnline;
  }

  public get isPrintingEnableTitle() {
    if(!this.data.PrintingLayouts){
      return "No labels";
    }
    const selectedLayout = this.data
    .PrintingLayouts
    .find(l => l.id == this.data.DefaultLayoutId);

    if (!selectedLayout) {
      return "Select label";
    }
    
    if (selectedLayout.format == LayoutFormatType.Sld && !this.data.CurrentPrinter.isNewPrintClient) {
      return 'The Sld label format supported only by new printing';
    }

    if (!this.data.isAvailablePrint) {
      return 'You haven\'t available printers';
    }

    if (!selectedLayout) {
      return 'Select label';
    }

    if (this.searchEmpty) {
      return 'Select any product';
    }

    if (!this.isPrinterOnline) {
      return 'Selected printer is offline. Select online printer.';
    }
    
    return 'Run to print selected products';
  }

    showPopUp = false;
    popUpTitle: string;
    ngbModalOptions: NgbModalOptions = {
        size: 'lg',
        backdrop : 'static',
        keyboard : false,
        centered: true
    };

    @Input() data: RequiredChildsData;

    constructor(
      private PrintService: PrintingService,
      private errorModalService: ErrorModalService,
      private modalService: NgbModal,
      private getUrlService: UrlBuilderService,
      private jobNotificationService: PrintJobNotificationService, 
      private productsService: ProductService) {
        this.jobNotificationService.addChangedListener(this.onChanged.bind(this));
		    this.jobNotificationService.startConnection();
      }

    async ngOnInit() {
      this.loading = true;

      this.allowSourceToggle = this.isAllowSourceToggle(this.data);
      this.usePortalSource = this.isDefaultPortalSource(this.data);

      this.getPrintHistory(1);
      this.initializeSignalRConnection();
    }

    ngDoCheck() {
      this.isPrinterOnline = this.data.CurrentPrinter?.status == State.Online;
      this.searchEmpty = !this.product;
    }

    private onChanged(model: IPrintJobViewModel) {
      console.log('On changed', model);
      this.printJobs = this.printJobs.map(_ => {
        if(_.Id == model.id){
          _.Status = model.status;
        }
        return _;
      });
    }

    public initializeSignalRConnection(): void {
      const baseUrl = environment.printServiceBaseUrl  + '/signalr';
      this.connection = $.hubConnection(baseUrl);
      this.proxy = this.connection.createHubProxy('printJobControllerHub');

      this.proxy.on('jobcanceled', (id) => {
          console.log('signalr cancel ' + id);
          this.setStatus(id, PrintingJobStatus.Canceled);
      });

      this.proxy.on('jobpostponed', (id) => {
          console.log('signalr postpone ' + id);
          this.setStatus(id, PrintingJobStatus.Postponed);
      });

      this.proxy.on('jobpaused', (id) => {
          console.log('signalr pause ' + id);
          this.setStatus(id, PrintingJobStatus.Paused);
      });

      this.proxy.on('jobinprogress', (id) => {
          console.log('signalr inProgress ' + id);
          this.setStatus(id, PrintingJobStatus.InProgressOld);
      });

      this.proxy.on('jobsuccess', (id) => {
          console.log('signalr success ' + id);
          this.setStatus(id, PrintingJobStatus.Success);
      });

      this.proxy.on('jobfailed', (id) => {
          console.log('signalr failed ' + id);
          this.setStatus(id, PrintingJobStatus.Failed);
      });

      this.proxy.on('jobcreated', () => {
          console.log('signalr job created');
      });

      this.connection.start().done((data: any) => {
        const location = this.data.selectedLocation;
          this.proxy.invoke('Connect', location.internalId + location.internalType.toLowerCase());
          console.log('Connected to printJobControllerHub');
      }).catch((error: any) => {
          console.log('webClientHub error -> ' + error);
      });
    }

    private setStatus(printJobId: number, status: PrintingJobStatus) {
        this.printJobs.forEach((printJob) => {
            if (printJob.Id == printJobId) {
                printJob.Status = status;
            }
        });
    }

    ngOnDestroy() {
        this.connection.stop();
        this.jobNotificationService.removeChangedListener();
        this.jobNotificationService.stopConnection();
    }

    closePopUp() {
      this.showPopUp = false;
      this.product = undefined;
    }

    printProduct() {
        if (!this.product || !this.data.DefaultLayoutId) {
            return;
        }
        const splitted = this.product.Name.split('|');
        if (splitted[0] === '' || splitted[0] === ' ') {
          this.popUpTitle = 'Attention';
          this.showPopUp = true;
          this.addSkuUrl = this.getUrlService.getUrltoProduct(this.product.ProductId, this.data);
        } else {
          if (this.quantity > 0) {
            this.copyAndPrint([this.product]);
          }
        }
    }

    private doPrint(searchProducts: SearchProductModel[]): Observable<any> {
      return this.PrintService.printProducts(this.data, searchProducts).pipe(
        tap( _=> {
          this.selectedPage = 1;
          this.getPrintHistory(this.selectedPage);
          this.product = undefined;
          this.quantity = 1;
        }),
        catchError(_ => {
          this.errorModalService.open('Error', 'when printing product');
          return throwError(_);
        })
      );
    }

    getPrintHistory(page: number) {
      this.loading = true;
      page = page <= 0 ? 1 : page;
      page = (page > this.totalPages ? this.totalPages : page) || 1;
      this.currentPage = page;

      this.PrintService.getPrintHistory(
        this.usePortalSource,
        page,
        this.data.selectedLocation,
        this.data.vendorId
      ).subscribe(res => {
        this.printJobs = res;
        const maxPage = Math.ceil(res.totalCount / this.PrintService.take);
        this.totalPages = maxPage;
        this.pages = []
        const maxCells = maxPage > 11 ? 11 : maxPage;
        let index = page >= 6 ? page - 5 : 1;
        for (let i = 1; i <= maxCells; i++) {
          if (index <= maxPage) {
            this.pages.push(index);
            index++;
          }
        }
        this.selectedPage = page;
        this.loading = false;
      },
      err => {
        console.log(err);
        this.loading = false;
        this.errorModalService.open('Error', 'when try get print history');
      });
    }

  formatMatches = (value: any) => value.Name || '';

  searchProducts = (text$: Observable<string>) => this.doSearch(text$);

  private doSearch(text$: Observable<string>): Observable<any> {
    return text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
          this.PrintService.searchProduct(term).pipe(
          catchError(() => {
          return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );
  }

  openMultipleProductsModal(isRequest) {
    const modalRef = this.modalService.open(ProductModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.onSearchCallback = this.doSearch.bind(this);
    modalRef.componentInstance.onCopyProductCallback = this.copyAndPrint.bind(this);
    modalRef.componentInstance.isPrinting = true;
    modalRef.componentInstance.layouts = this.data.PrintingLayouts;
    modalRef.componentInstance.data = this.data;
    if (typeof this.product != 'string') {
      const product: any = this.product;
      if(product){
        product.Quantity = this.quantity;
        product.isAdded = true;
      }
    }
    if (isRequest) {
      modalRef.componentInstance.isPrintRequest = true;
    }
    modalRef.componentInstance.firstProduct = this.product;
  }

  replaceNameForTitle(name) {
      return name.replace(new RegExp(nameSplitter, 'g'), '\n');
  }

  printRequest() {
    this.openMultipleProductsModal(true);
  }

  private isAllowSourceToggle(data: RequiredChildsData): boolean {
    let newPrinters = 0;
    let oldPrinters = 0;

    data.Printers.forEach(printer => {
      if (printer.isNewPrintClient) {
        newPrinters++;
      } else {
        oldPrinters++;
      }
    });

    return !!newPrinters && !!oldPrinters;
  }

  private isDefaultPortalSource(data: RequiredChildsData): boolean {
    return data.Printers.some(_ => _.isNewPrintClient);
  }

  private buildCopyProducts(products: any[]): Observable<ProductCopyModelResponse[]> {
    const productsToSave = this.buildProductsToSaveList(products);
    return this.productsService.copyProducts(productsToSave);
  }

  buildProductsToSaveList(products: any[]): ProductCopyModelRequest[] {
    const productsToSave: ProductCopyModelRequest[] = [];
    products.forEach(p => {
        productsToSave.push({
          ProductId: p.ProductId,
          VariantIds: [p.VariantId]
        });
    });

    return productsToSave.filter(p => p.ProductId);
  }

  copyAndPrint(products: any[]){
    this.loading = true;
    this.buildCopyProducts(products).pipe(
      switchMap(importedProducts => {
        const importedProductsDic = importedProducts.reduce<{[key: string]: ProductCopyModelResponse}>((acc, cur) => {
          acc[cur.PartnerProductId + cur.PartnerVariandId] = cur;
          return acc;
        }, {});
        
        const productsModel = products.map(p => {
          return [{ ProductId: importedProductsDic['' + p.ProductId + p.VariantId]?.ProductId,
              Quantity: p.Quantity ?? this.quantity,
              Name: p.Name,
              Sku: p.Sku }];
        })
        .flat(1)
        .filter(_ => !!_.ProductId && _.Quantity > 0)
        .map<SearchProductModel>(_ => {
          return {
            Name: _.Name,
            ProductId: _.ProductId,
            Quantity: _.Quantity,
            Sku: _.Sku,
          }
        });
      return this.doPrint(productsModel);
      })
    ).subscribe();
  }
}
