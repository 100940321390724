<br/>
<div *ngIf="loading && !isSyncInProgress && !isLoadInProgress && !showContent" align="center">
  <br/>
  <div class="loader"></div>
</div>
<div [hidden]="!isLoadInProgress">
  <h3 align="center">Loading Inventory</h3>
  <app-progress-bar [data]='progressBarLoadData' (onProgress)="loadInProgress()" (onFinish)="finishLoad()"></app-progress-bar>
</div>
<div [hidden]="!(isSyncInProgress && showContent)">
  <h3 align="center">Syncing Inventory Counts to {{data.platform}}</h3>
  <app-progress-bar [data]='progressBarData' (onProgress)="inProgress()" (onFinish)="finishSync()"></app-progress-bar>
</div>
<ng-container *ngIf="!loading && syncTab">
  <app-sync-history [data]='data'></app-sync-history>
</ng-container>
<ng-container *ngIf="!loading && !syncTab && !isSyncInProgress && !isLoadInProgress && showContent">
  <div class="main-box flex-bottom-panel">
    <div class="flex-top-panel1-alt">
      <div class="container-flex">
        <div class="last-reset-box1">
          <label [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}">Last Audit:&nbsp;</label>
          <div class="leaf-container" *ngIf="lastAuditDate">
            <span  [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}" >{{lastAuditDate | localDate}}
              <img class="leef" *ngIf="inventoryStatus == 'completed'" src="./assets/images/leaf.png" title="Inventory is fresh">
              <img class="leef" *ngIf="inventoryStatus != 'completed' && inventoryStatus != 'error'" src="./assets/images/leafbrown.png" title="Inventory is NOT fresh">
              <img class="leef" style="width: 13px; margin-bottom: 3px;" *ngIf="inventoryStatus == 'error'" src="./assets/images/bol-failure.png" title="Error">
            </span>
            <span  *ngIf="!lastAuditDate">Never</span>
          </div>
        </div>
        <div class="last-reset-box4">
          <button type="submit" class="btn btn-info simpleRfIdColor" (click)="getInventory()" >Load</button>
        </div>
        <!-- <div *ngIf="data.showScans" class="last-reset-box3">
          <span class="scans-title">Scans:</span>
        </div> -->
      </div>
    </div>
    <div class="modal align-middle" style="display: block;" *ngIf="isShowSpreadsheetPopup">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inventory Spreadsheet download</h5>
            <button class="close" (click)="closePopup()">&times;</button>
          </div>
          <div class="modal-footer">
            <button class="btn btn-info simpleRfIdColor" (click)="getXmlCsvSpreadsheet('xlsx')">XLSX</button>
            <button class="btn btn-info simpleRfIdColor" (click)="getXmlCsvSpreadsheet('csv')">CSV</button>
          </div>                
        </div>
      </div>
    </div>
    <!-- <div [ngClass]="{'col-md-2': data.isBigCommerce || data.isShopify, 'col-md-4': data.isFulfil || data.isVend}"> -->
    <div class="sync-box" style="margin-right: 0;">
      <div class="margin-right" style="padding-top: 4px">
        <div *ngIf="isShowSpreadsheetButton">
          <!-- <button class="btn btn-info" style="background-color: rgb(84, 143, 214);" (click)="openPopup()">Download spreadsheet</button> -->
          <span (click)="openPopup()" title="Download spreadsheet. Select a products."><i class="fas fa-file-download fa-2x" style="color: rgb(84, 143, 214)"></i></span>
        </div>
        <div *ngIf="!isShowSpreadsheetButton">
          <span (click)="openPopup()" title="Download spreadsheet. Select a products."><i class="fas fa-file-download fa-2x" style="color: rgb(149, 214, 234)"></i></span>
        </div>
      </div>
      <div class="float-right">
        <button class="btn btn-info simpleRfIdColor" (click)="changeTab()"><span class="fa fa-calendar-alt"></span> History</button>
        <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" class="btn btn-info simpleRfIdColor" [disabled]="!inventoryForm.form.valid || countToSync === 0 || isSyncInProgress" 
          (click)="confirmToUpdate()" title="Items to sync: {{itemsToSync}}">Sync 
          <span *ngIf="inventoryStorage.isFirstloaded">({{countToSync}})</span>
        </button>
        <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" 
          class="btn btn-info simpleRfIdColor" (click)="openPrintModal()" 
          [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
          [openDelay]="300" [closeDelay]="300">
          Print 
          <span *ngIf="inventoryStorage.isFirstloaded">({{countItemsToPrint}})</span>
        </button>
      </div>        
    </div>
  </div>
  <div class="main-box flex-bottom-panel"  style="margin-top: 20px;">
    <div class="search-box flex-bottom-panel1">
      <input class="form-control" [(ngModel)]="searchString" (ngModelChange)="getInventoryTab()" placeholder="Search...">
    </div>
    <div class="btn-group flex-bottom-panel2">
      <button class="btn btn-primary btn-blue" [ngClass]="{ 'active' : activeSourceTab == InventorySource.Mismatch }" (click)="getMismatchInventory()">Mismatch 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventorySyncCount}})</span>
      </button>
      <button class="btn btn-primary btn-blue" [ngClass]="{ 'active' : activeSourceTab == InventorySource.Match }" (click)="getMatchInventory()">Match 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryMatchCount}})</span>
      </button>
    </div>
    <div class="btn-group" [ngClass]="{'flex-bottom-panel3': activeSourceTab == InventorySource.Mismatch,
                                        'flex-bottom-panel3-alt': activeSourceTab != InventorySource.Mismatch
                                      }">
      <button class="btn btn-primary btn-white" [ngClass]="{ 'active' : activeFilterColor == 'white' }"  (click)="lineFilter(colorFilters.default)" >All 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventorySyncCount}})</span>
      </button>
      <button class="btn btn-primary btn-orange" [ngClass]="{ 'active' : activeFilterColor == 'green' }" (click)="lineFilter(colorFilters.deflatedFilter)">Deflated 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryDeflatedCount}})</span>
      </button>
      <button class="btn btn-primary btn-red"  [ngClass]="{ 'active' : activeFilterColor == 'red'}" (click)="lineFilter(colorFilters.inflatedFilter)">Inflated 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryInflatedCount}})</span>
      </button>
      <button class="btn btn-primary btn-oos" style="margin-right: auto" [ngClass]="{ 'active' : activeFilterColor == 'oss'}" 
        (click)="lineFilter(colorFilters.OoSFilter)">Out of stock 
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryOutOfStockCount}})</span>
      </button>
    </div>
  </div>
  <br/>
  <div class="row">
    <form #inventoryForm="ngForm" class="col-md-12">
      <table class="table col-md-12">
        <thead>
          <tr *ngIf="inventoryStorage.isFirstloaded">
            <th></th>
            <th></th>
            <th></th>
            <th *ngIf="showBinLocation"></th>
            <th class="title-center">{{inventoryStorage.allRfidCount}}</th>
            <th *ngIf="isOrdersAccounted && !data.isFulfil" style="padding-left: 25px;">{{inventoryStorage.allOrderCount}}</th>
            <th *ngIf="!data.isFulfil">{{inventoryStorage.allNewCount}}</th>
            <th class="title-center">{{inventoryStorage.allCurrentCount}}</th>
            <th></th>
          </tr>
          <tr>
            <th>Image</th>
            <th width="43%">Product Name</th>
            <th class="table-sku">{{productKey}}</th>
            <th *ngIf="showBinLocation">Bin Location</th>
            <th style="width: 47px;">RFID</th>
            <th style="width: 100px" *ngIf="isOrdersAccounted && !data.isFulfil && !data.isVend">
              <span style="margin-right: 7%;">-</span>
              Orders
              <span style="float: right; margin-left: 5px">=</span>
            </th>
            <th *ngIf="!data.isFulfil" class="sort-table-header" style="width: 100px" (click)="sortByAvailable()">Available
              <img *ngIf = 'isSortedByAvailableAscending == "default"' src="./assets/images/sort.png">
              <img *ngIf = 'isSortedByAvailableAscending == "asc"' src="./assets/images/sort-up.png">
              <img *ngIf = 'isSortedByAvailableAscending == "desc"' src="./assets/images/sort-down.png">
              
            </th>
            <th class="sort-table-header" style="width: 160px; padding-left: 35px;" (click)="sortByPartner()">
              <span style="margin-right: 5%;; color: black">vs</span>
              {{data.platform}}
              <img *ngIf = 'isSortedByPatnerAscending == "default"' src="./assets/images/sort.png">
              <img *ngIf = 'isSortedByPatnerAscending == "asc"' src="./assets/images/sort-up.png">
              <img *ngIf = 'isSortedByPatnerAscending == "desc"' src="./assets/images/sort-down.png">
            </th>
            <th width="3%"> <!-- *ngIf="activeSourceTab != InventorySource.Match"> -->
              <input type="checkbox" name="syncAll" [(ngModel)]="syncAll" [checked]="syncAll" (ngModelChange)="adjustSelection()" [disabled]="selectAllDisabled"/>
            </th>
          </tr>
        </thead>
          <tbody>
            <tr *ngFor="let product of filteredItems let i = index"
              [ngClass]="{'alert-warning' : product.FilterType == 'Default',
                         'background-green': product.FilterType == 'Deflated', 
                         'background-orange': product.FilterType == 'Inflated',
                         'background-red': product.FilterType == 'OutOfStock'}" >
              <td>
                <app-image-hendler [product]="product"></app-image-hendler>
              </td>
              <td>{{product.Title}}</td>
              <td>{{product.Sku}}</td>
              <td *ngIf="showBinLocation">{{product.Bpn}}</td>
              <td class="title-center" title="RFID">{{product.RfidCount}}</td>
              <td *ngIf="isOrdersAccounted && !data.isFulfil && !data.isVend" style="padding-left: 25px;" 
                title="Orders" class="cursor-pointer" (click)="openOrdersInfoModal(product)" >{{product.OrdersCount}}
              </td>
              <td class="new-count-cell" *ngIf="activeSourceTab == InventorySource.Mismatch && !data.isFulfil" title="New">
                <span class="new-count-text" *ngIf="!product.editable" (click)="openEditor(product)">{{product.NewCount}}</span>
                <span *ngIf="!product.editable" class="icon-pencil-edit-button pencil-icon" (click)="openEditor(product)"></span>
                <input class="form-control" type="number" min="0"
                  *ngIf="product.editable" [(ngModel)]="product.NewCount" [ngModelOptions]="{standalone: true}">
              </td>
              <td *ngIf="activeSourceTab == InventorySource.Match && !data.isFulfil" title="New">{{product.NewCount}}</td>
              <td title="Current" class="title-center" [class.text-yellow]="product.isAdjusted">{{product.CurrentCount}}</td>
              <td *ngIf="activeSourceTab == InventorySource.Mismatch">
                <input type="checkbox"  name='sync{{i}}' [checked]="product.sync"  (change)="changeSelection(product)" (click)="toggleSelected(i, $event)"
                  [attr.disabled]="product.NewCount == product.CurrentCount ? true : null" oninput="this.value = this.value.replace(/\D/g, '')"/>
              </td>
              <td *ngIf="activeSourceTab == InventorySource.Match">
                <input type="checkbox"  name='sync{{i}}' [checked]="product.sync"  (change)="changeSelection(product)" (click)="toggleSelected(i, $event)" />
              </td>
            </tr>
          </tbody>
      </table>
    </form>
  </div>
  <div class="row" style="margin-left: 0;  margin-right: 0;">
    <div class="col-md-3 offset-md-7 text-center">
      <ng-container *ngIf="allowMakeVisible">
        <span style="padding-top:5px;">
          <input type="checkbox" name="makeVisible" [(ngModel)]="makeVisible">&nbsp;<label for="makeVisible">Make visible</label>
        </span>
      </ng-container>
    </div>
  </div>
  <div *ngIf="filteredItems.length > 0" style="text-align: end;">
    <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" class="btn btn-info simpleRfIdColor button-footer" 
      [disabled]="!inventoryForm.form.valid || countToSync === 0 || isSyncInProgress" 
      (click)="confirmToUpdate()" title="Items to sync: {{itemsToSync}}">Sync 
      <span *ngIf="inventoryStorage.isFirstloaded">({{countToSync}})</span>
    </button>
    <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" 
      class="btn btn-info simpleRfIdColor button-footer" 
      [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
      [openDelay]="300" [closeDelay]="300" 
      (click)="openPrintModal()">
      Print 
      <span *ngIf="inventoryStorage.isFirstloaded">({{countItemsToPrint}})</span>
    </button>
  </div>
</ng-container>