import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { UrlBuilderService } from 'app/core/url-builder';
import { UserSettingsInfoService } from 'app/settings/settings.service';
import { CustomNgbDateParserFormatter } from 'app/printing-modal/CustomNgbDateParserFormatter'
import { RequiredChildsData } from '../core/required-childs-data';
import { State, PrintingService, ICreatePrintJobModel, LayoutFormatType, SearchProductModel } from 'app/printing/printing.service';
import { CurrentShop } from 'app/main/currentShopModel';
import { ProductCopyModelRequest, ProductCopyModelResponse, ProductService } from 'app/products/products.service';

@Component({
    selector: 'product-modal-component',
    template: `

  <div class="modal-header">
      <h4 class="modal-title" *ngIf="!isPrintRequest">Choose products to print</h4>
      <h4 class="modal-title" *ngIf="isPrintRequest">New print tag request</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div *ngIf="isPrintRequest">
          <div class="product-row">
              <input type="text" class="form-control" [(ngModel)]="name" placeholder="Name">
          </div>
          <div class="product-row">
              <div class="col-md-6 datepicker-block inline">
                  <input class="btn btn-outline-secondary calendar input-group-append" (click)="d.toggle()" type="button">
                  <input class="form-control datepicker-input" placeholder="Requested Arrival Date"
                      name="dp" [(ngModel)]="dueDate" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate">
              </div>
              <!--<input type="text" class="form-control col-md-6 inline" [(ngModel)]="dueDate" placeholder="Requested Arrival Date: yyyy-mm-dd">-->
              <input type="text" class="form-control col-md-6 inline" [(ngModel)]="company" placeholder="Company name">
          </div>
          <p>
              <button type="button" class="btn btn-info" (click)="isCollapsed = !isCollapsed"
                      [attr.aria-expanded]="!isCollapsed" aria-controls="serviceDetalis">
              Service details
              </button>
          </p>
          <div id="serviceDetalis" [ngbCollapse]="isCollapsed">
              <hr/>
              <div class="product-row">
                  <input type="text" class="form-control" [(ngModel)]="contact" placeholder="Contact">
              </div>
              <div class="product-row">
                  <input type="text" class="form-control" [(ngModel)]="address" placeholder="Address">
              </div>
              <div class="product-row">
                  <input type="text" class="form-control col-md-6 inline" [(ngModel)]="email" placeholder="Email">
                  <input type="text" class="form-control col-md-5 inline float-right" [(ngModel)]="phone" placeholder="Phone number">
              </div>
              <div class="product-row">
                  <input type="text" class="form-control" [(ngModel)]="comment" placeholder="Comment">
              </div>
          </div>

          <hr/>
      </div>
      <h6>Total: {{getTotalCount()}}</h6>
      <div *ngFor="let product of products; index as i" class="product-row">
          <div *ngIf="product.isAdded">
              <div class="col-md-8 inline product-title">
                  <span>{{product.Name}}</span>
              </div>
              <div class="col-md-2 inline">
                  <span>{{product.Quantity}}</span>
              </div>
              <input type="button" value="×" class="btn form-control col-md-1 inline" (click)="remove(i)">
          </div>
          <div *ngIf="!product || !product.isAdded" class="product-row show">
              <input id="typeahead-http" type="text" [(ngModel)]="currentProduct" [ngbTypeahead]="searchProducts" [resultFormatter]="formatMatches"
              [inputFormatter]="formatMatches" class="form-control col-md-8 inline" placeholder="SKU or Name + Variants..."/>
              <input type="text" class="form-control col-md-2 inline" [(ngModel)]="productQuantity">
              <input type="button" value="+" class="btn btn-info form-control col-md-1 inline" (click)="add()" [disabled]="!currentProduct.ProductId">
          </div>
      </div>
      <h6>Total: {{getTotalCount()}}</h6>
  </div>

  <div class="modal-footer footer-box">
      <div class=" footer-box1">
      </div>
      <div class="footer-box2">
          <div class="layout-box">
              <span class="layout-box1"><h6>Label: </h6></span>
              <select type="text" [(ngModel)]="layoutId"  (ngModelChange)="setNewLabel()" class="form-control" placeholder="Layout" *ngIf="isPrinting">
                  <option value="0" disabled="true"><span>Select label template...</span></option>
                  <option *ngFor="let layout of layouts" [value]="layout.id">
                      {{layout.name}}
                  </option>
              </select>
          </div>
      </div>
      <div class="footer-box3">
          <button type="button" class="btn btn-outline-dark" (click)="print()" 
              [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
              [openDelay]="300" [closeDelay]="300">
              Print
          </button>
          <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Cancel</button>
      </div>

      <div *ngIf="showPopUp"  id="popUpOverlay" class="footer-box4">
          <div class="popUpContent">
              <h3 style="font-weight: bold">{{popUpTitle}}</h3>
              <hr>
              To print the desired product please enter its SKU
              <hr>
              <div>
                  <a role="button" class="btn btn-sm btn-info popUpBtn" (click)="closePopUp()" target="_blank" href={{addSkuUrl}}>Add SKU</a>
                  <button class="btn btn-sm btn-info" style="background-color: rgb(84, 143, 214); display: inline" (click)="closePopUp()" >Cancel</button>
              </div>
          </div>
      </div>
  </div>

  `,
    styleUrls: ['./product-modal.component.css'],
    providers: [{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }],
    standalone: false
})
export class ProductModalComponent implements OnInit {
    @Input() firstProduct;
    @Input() openSkuAlertPopup;
    @Input() isPrintRequest;
    @Input() data: RequiredChildsData;
    @Input() isPrinting: boolean;
    @Input() layouts;
    @Input() onSearchCallback: (text$: Observable<string>) => Observable<any>;
    @Input() onCopyProductCallback: (products: any) => Observable<any>;
    currentProduct: any = {};
    products: any[];
    searching = false;
    searchFailed = false;
    productQuantity = 1;
    showPopUp = false;
    popUpTitle: string;
    addSkuUrl: string;
    defaultAddress: string;
    address: string;
    phone: string;
    dueDate;
    name: string;
    contact: string;
    email: string;
    company: string;
    comment: string;
    isCollapsed: boolean;
    minDate;
    layoutId = 0;
    isPrinterOnline: boolean;

    public get isPrintingEnable() {
        if(!this.data.PrintingLayouts){
          return false;
        }
        const selectedLayout = this.data
          .PrintingLayouts
          .find(l => l.id == this.data.DefaultLayoutId);

          if (!selectedLayout) {
            return false;
          }
    
         const labelSupport = this.data.CurrentPrinter.isNewPrintClient
          || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.CurrentPrinter.isNewPrintClient)
    
        return this.products.length > 1 && !!selectedLayout && labelSupport && this.isPrinterOnline;
    }

    public get isPrintingEnableTitle() {
        if(!this.data.PrintingLayouts){
          return "No labels";
        }
        const selectedLayout = this.data
        .PrintingLayouts
        .find(l => l.id == this.data.DefaultLayoutId);

        if (!selectedLayout) {
          return "Select label";
        }
    
        if (selectedLayout.format == LayoutFormatType.Sld && !this.data.CurrentPrinter.isNewPrintClient) {
          return 'The Sld label format supported only by new printing';
        }

        if (this.products.length <= 1) {
          return 'Select any product';
        }
    
        if (!selectedLayout ) {
          return 'Select label';
        }
    
        if (!this.isPrinterOnline) {
          return 'Selected printer is offline. Select online printer.';
        }
        
        return 'Run to print selected products';
    }

    formatMatches = (value: any) => value.Name || '';

    constructor(
      public activeModal: NgbActiveModal,
      private errorModalService: ErrorModalService,
      private getUrlService: UrlBuilderService,
      private userSettingsService: UserSettingsInfoService,
      private printingService: PrintingService,
      private productsService: ProductService,) {
        const savedAddress = window.localStorage.getItem('address');
        if (savedAddress && savedAddress != '') {
            // TODO:: check is not BigCommerce (maybe on server side)
            this.userSettingsService.getLocation(this.data.shopName, this.data.platform).subscribe(res => {
                if (res && res.Address) {
                    this.address = res.Address;
                    this.defaultAddress = res.Address;
                } else {
                    this.address = window.localStorage.getItem('address');
                }
            });
        }

        const now = new Date();
        this.minDate = {
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            day: now.getDate()
        };

        this.name = `Print tag request ${now.toLocaleString()}`;
      }

    ngOnInit() {
        this.products = this.firstProduct && this.firstProduct.ProductId ? [this.firstProduct, {}] : [this.currentProduct];
        this.isCollapsed = true;
        this.layoutId = this.data.DefaultLayoutId;
        this.isPrinterOnline = this.data.CurrentPrinter?.status == State.Online;
    }

    init() {
        this.currentProduct = {};
        this.productQuantity = 1;
    }

    searchProducts = (text$: Observable<string>) => this.onSearchCallback(text$);

    getTotalCount() {
        return this.products.filter(_ => _.isAdded)
          .map(_ => + _.Quantity)
          .reduce((acc, cur) => acc + cur, 0);
    }

    print() {
        console.log('Print products and close modal');
          this.onCopyProductCallback(this.products.filter(_ => !!_.ProductId && _.Quantity > 0));
            this.activeModal.close(true);
    }

    add() {
        const splitted = this.currentProduct.Name.split('|');
        if (splitted[0] === '' || splitted[0] === ' ') {
            this.popUpTitle = 'Attention';
            this.showPopUp = true;
            this.addSkuUrl = this.getUrlService.getUrltoProduct(this.currentProduct.ProductId, this.data);
            this.currentProduct = {};
        } else {
            if (this.productQuantity <= 0) {
                this.productQuantity = 1;
            }
            this.currentProduct.isAdded = true;
            this.currentProduct.Quantity = this.productQuantity;
            this.products[this.products.length - 1] = this.currentProduct;
            this.products.push({});
            this.init();
        }
        console.log('Should be added');
    }

    closePopUp() {
      this.showPopUp = false;
      this.currentProduct = {};
    }

    remove(index) {
        this.products.splice(index, 1);
    }

    setNewLabel() {
        this.data.DefaultLayoutId = this.layoutId;
        const shops: Array<CurrentShop> = JSON.parse(window.localStorage.getItem('context'));
        shops.forEach(el => {
          if (el.shopName == this.data.shopName && el.platform == this.data.platform) {
            el.layoutId = this.data.DefaultLayoutId
          }
        });
        window.localStorage.setItem('context', JSON.stringify(shops));
    }
}
