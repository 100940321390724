export class ProgressBarDataModel{
   locationExternalId: string;
   isTaskInProgress: boolean;
   isWasStarted: boolean;
   operationType: number;
   isSync: boolean;
   hangFireSuccess: boolean;
   hangFireFail: boolean;
   jobRun: boolean;
   errorMessage: string;
   hangfireJobId: string;
   loadOnHandQuantity: boolean;
   auditCanceled: boolean;
   hangFireJobDateCreate: string;
}