<br/>
<div *ngIf="loading && !isSyncInProgress && !isLoadInProgress && !showContent" align="center">
  <br/>
  <div class="loader"></div>
</div>
<div [hidden]="!isLoadInProgress">
  <h3 [hidden]="hiddenTitle" align="center">Loading Inventory</h3>
  <app-progress-bar [data]='progressBarLoadData' (onProgress)="loadInProgress()" (onFinish)="finishLoad()"></app-progress-bar>
</div>
<div [hidden]="!(isSyncInProgress && showContent)">
  <h3 align="center">Syncing Inventory Counts to {{data.platform}}</h3>
  <app-progress-bar [data]='progressBarData' (onProgress)="inProgress()" (onFinish)="finishSync()"></app-progress-bar>
</div>
<ng-container *ngIf="!loading && syncTab">
  <app-sync-history [data]='data'></app-sync-history>
</ng-container>
<ng-container *ngIf="!loading && !syncTab && !isSyncInProgress && !isLoadInProgress && showContent">
  <div class="main-box flex-bottom-panel">
    <div class="flex-top-panel1-alt">
      <div class="container-flex">
        <div class="last-reset-onhand-box1">
          <div class="date-box">
            <label class="date-box1" [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}">Last Audit Date:&nbsp;</label>
            <div class="leaf-container date-box2" *ngIf="lastAuditDate else nolastAuditDate">
              <span  [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}" >{{lastAuditDate | localDate}}
              </span>
            </div>
            <ng-template #nolastAuditDate>
              <div class="leaf-container date-box2">
                <span class="red">N/A</span>
              </div>
            </ng-template>
          </div>
          <div class="date-box">
            <label class="date-box1" [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}">Last Scan Date:&nbsp;</label>
            <div class="leaf-container date-box2"  *ngIf="lastScanDate; else nolastScanDate">
              <span  [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}" >
                {{lastScanDate | localDate}}
                <img class="leef" *ngIf="inventoryStatus == 'completed'" src="./assets/images/leaf.png" title="Inventory is fresh">
                <img class="leef" *ngIf="inventoryStatus != 'completed' && inventoryStatus != 'error'" src="./assets/images/leafbrown.png" title="Inventory is NOT fresh">
                <img class="leef" style="width: 13px; margin-bottom: 3px;" *ngIf="inventoryStatus == 'error'" src="./assets/images/bol-failure.png" title="Error">
              </span>
            </div>
            <ng-template #nolastScanDate>
              <div class="leaf-container date-box2">
                <span class="red">N/A</span>
              </div>
            </ng-template>
          </div>
          <div class="date-box">
            <label class="date-box1" [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}">Last Sync Date:&nbsp;</label>
            <div class="leaf-container date-box2"  *ngIf="lastSyncDate else nolastSyncDate">
              <span class="green" >
                {{lastSyncDate | localDate}}
              </span>
            </div>
            <ng-template #nolastSyncDate>
              <div class="leaf-container date-box2">
                <span class="red">N/A</span>
              </div>
            </ng-template>
          </div>
          <div class="date-box">
            <label class="date-box1" [ngClass]="{'red': inventoryStatus != 'completed', 'green' : inventoryStatus == 'completed'}">Last OnHand Load:&nbsp;</label>
            <div class="leaf-container date-box2" *ngIf="loadDate; else noLoadDate">
              <span class="green">{{loadDate | localDate}}</span>
            </div>
            <ng-template #noLoadDate>
              <div class="leaf-container date-box2">
                <span class="red">N/A</span>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="last-reset-box4">
          <div class="last-reset-buttons">
            <!-- <button type="submit"
              class="btn btn-info simpleRfIdColor button-start" (click)="confirmToLoad()" >
              RFID vs. On Hand
            </button> -->
            <button type="submit" 
              class="btn btn-info simpleRfIdColor button-reload-onhand" (click)="confirmToLoad()" >
              RFID vs. On Hand
            </button>
            <button [disabled]="!loadDate" type="submit" 
              class="btn btn-info simpleRfIdColor button-reload-rfid" (click)="compareAudit()" >
              Reload Verified RFID
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal align-middle" style="display: block;" *ngIf="isShowSpreadsheetPopup">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inventory Spreadsheet download</h5>
            <button class="close" (click)="closePopup()">&times;</button>
          </div>
          <div class="modal-footer">
            <button class="btn btn-info simpleRfIdColor" (click)="getXmlCsvSpreadsheetOnHand('xlsx')">XLSX</button>
            <button class="btn btn-info simpleRfIdColor" (click)="getXmlCsvSpreadsheetOnHand('csv')">CSV</button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="sync-box" style="margin-right: 0;">
      <!-- <div class="margin-right" style="padding-top: 4px">
        <div *ngIf="isShowSpreadsheetButton">
          <span (click)="openPopup()" title="Download spreadsheet. Select a products."><i class="fas fa-file-download fa-2x" style="color: rgb(84, 143, 214)"></i></span>
        </div>
        <div *ngIf="!isShowSpreadsheetButton">
          <span (click)="openPopup()" title="Download spreadsheet. Select a products."><i class="fas fa-file-download fa-2x" style="color: rgb(149, 214, 234)"></i></span>
        </div>
      </div> -->
      <div class="sync-box-buttons">
        <button class="btn btn-info simpleRfIdColor sync-box-button-history" (click)="changeTab()"><span class="fa fa-calendar-alt"></span> History</button>
        <button *ngIf="activeSourceTab == InventorySource.Mismatch && auditInProgress" type="submit" class="btn btn-info simpleRfIdColor sync-box-button-other"
          [disabled]="countToSync === 0 || isSyncInProgress"
          (click)="confirmToUpdateOnHand(null)" title="Items to sync: {{itemsToSync}}">Sync
          <span *ngIf="inventoryStorage.isFirstloaded">({{countToSync}})</span>
        </button>
        <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" class="btn btn-info simpleRfIdColor sync-box-button-other"
          (click)="openPrintModal()"
          [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
          [openDelay]="300" [closeDelay]="300" 
        >Print
          <span *ngIf="inventoryStorage.isFirstloaded">({{countItemsToPrint}})</span>
        </button>
      </div>
    </div>
  </div>
  <div class="main-box flex-bottom-panel"  style="margin-top: 20px;">
    <div class="search-box-on-hand flex-bottom-panel1">
      <input class="form-control" [(ngModel)]="searchString" (ngModelChange)="getInventoryTabOnHand()" placeholder="Search...">
    </div>
    <div class="btn-group flex-bottom-panel2">
      <button class="btn btn-primary btn-blue" [ngClass]="{ 'active' : activeSourceTab == InventorySource.Mismatch }" (click)="getMismatchInventoryOnHand()">Mismatch
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventorySyncCount}})</span>
      </button>
      <button class="btn btn-primary btn-blue" [ngClass]="{ 'active' : activeSourceTab == InventorySource.Match }" (click)="getMatchInventoryOnHand()">Match
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryMatchCount}})</span>
      </button>
    </div>
    <div class="btn-group" [ngClass]="{'flex-bottom-panel3': activeSourceTab == InventorySource.Mismatch,
                                        'flex-bottom-panel3-alt': activeSourceTab != InventorySource.Mismatch
                                      }">
      <button class="btn btn-primary btn-white" [ngClass]="{ 'active' : activeFilterColor == 'white' }"  (click)="lineFilterOnHand(colorFilters.default)" >All
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventorySyncCount}})</span>
      </button>
      <button class="btn btn-primary btn-orange" [ngClass]="{ 'active' : activeFilterColor == 'green' }" (click)="lineFilterOnHand(colorFilters.deflatedFilter)">Deflated
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryDeflatedCount}})</span>
      </button>
      <button class="btn btn-primary btn-red"  [ngClass]="{ 'active' : activeFilterColor == 'red'}" (click)="lineFilterOnHand(colorFilters.inflatedFilter)">Inflated
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryInflatedCount}})</span>
      </button>
      <button class="btn btn-primary btn-oos" style="margin-right: auto" [ngClass]="{ 'active' : activeFilterColor == 'oss'}"
        (click)="lineFilterOnHand(colorFilters.OoSFilter)">Out of stock
        <span *ngIf="inventoryStorage.isFirstloaded">({{inventoryStorage.inventoryOutOfStockCount}})</span>
      </button>
    </div>
  </div>
  <br/>
  <div class="table-area-on-hand">
    <div *ngIf="inventoryStorage.isFirstloaded" class="row table-title-bottom">
      <div class="cell-small"></div>
      <div class="cell-text"></div>
      <div class="cell-text"></div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell">{{inventoryStorage.allRfidCount}}</div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell-order">{{inventoryStorage.allOrderCount}}</div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell">{{inventoryStorage.allNewCount}}</div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell">{{inventoryStorage.allCurrentCount}}</div>
      <div *ngIf="activeSourceTab == InventorySource.Mismatch && filteredItems.length > 0"  class="cell"></div>
      <div *ngIf="activeSourceTab == InventorySource.Mismatch" class="cell-small"></div>
    </div>
    <div class="row table-title-bottom">
      <div class="cell-small"><b>Image</b></div>
      <div class="cell-text"><b>Product Name</b></div>
      <div class="cell-text"><b>{{productKey}}</b></div>
      <div class="cell"><b>Verified RFID</b></div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell-order">
        <b>- Fulfilled Orders =</b>
      </div>
      <div *ngIf="auditInProgress && filteredItems.length > 0" class="cell">
        <b>Projected On Hand</b>
      </div>
      <div class="cell">
        <b>Shopify On Hand</b>
      </div>
      <div *ngIf="activeSourceTab == InventorySource.Mismatch && filteredItems.length > 0"  class="cell"></div>
      <div class="cell-small" *ngIf="activeSourceTab == InventorySource.Mismatch" >
        <input type="checkbox" name="syncAll"
          [(ngModel)]="syncAll"
          [checked]="syncAll"
          (ngModelChange)="adjustSelectionOnHand()"
          [disabled]="selectAllDisabled"/>
      </div>
    </div>
    <div *ngIf="existsContent" class="row">
      <cdk-virtual-scroll-viewport [style.overflow]="filteredItems.length > 0 ? 'scroll':'auto'" class="list-container lg"  [itemSize]="64" [bufferSize]="50">
        <div *cdkVirtualFor="let product of filteredItems let i = index templateCacheSize: 0" class="list-group-item"
              [ngClass]="activeSourceTab != InventorySource.NeverPrinted ? {'alert-warning' : product.FilterType == 'Default', 'background-green': product.FilterType == 'Deflated',
          'background-orange': product.FilterType == 'Inflated', 'background-red': product.FilterType == 'OutOfStock'} : ''" >
          <div class="cell-small">
            <app-image-hendler [product]="product"></app-image-hendler>
          </div>
          <div class="cell-text">{{product.Title}}</div>
          <div class="cell-text">{{product.Sku}}</div>
          <div class="cell" title="RFID">{{product.RfidCount}}</div>

          <div title="Orders" class="cursor-pointer cell-order" (click)="openOrdersInfoModal(product)" >
            {{product.OrdersCount}}
          </div>
          <div class="cell" title="New">{{product.NewCount}}</div>

          <div title="Current" class="cell" [class.text-yellow]="product.isAdjusted">{{product.CurrentCount}}</div>
          <div *ngIf="activeSourceTab == InventorySource.Mismatch" class="cell">
            <button *ngIf="product.NewCount >= 0 && product.NewCount != product.CurrentCount" type="submit" class="btn btn-info simpleRfIdColor sync-box-button-other"
              [disabled]="isSyncInProgress"
              (click)="oneUpdateOnHand(product)" title="Click to sync">Sync
            </button>
          </div>
          <div *ngIf="activeSourceTab == InventorySource.Mismatch" class="cell-small">
            <input type="checkbox" name='sync{{i}}' [checked]="product.sync"  (change)="changeSelectionOnHand(product)" (click)="toggleSelected(i, $event)"
            [disabled]="product.NewCount < 0" oninput="this.value = this.value.replace(/\D/g, '')"/>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <!--<div  *ngIf="auditInProgress" class="row" style="margin-left: 0;  margin-right: 0;">
    <div class="col-md-3 offset-md-7 text-center">
      <ng-container *ngIf="allowMakeVisible">
        <span style="padding-top:5px;">
          <input type="checkbox" name="makeVisible" [(ngModel)]="makeVisible">&nbsp;<label for="makeVisible">Make visible</label>
        </span>
      </ng-container>
    </div>
  </div>-->
  <div *ngIf="auditInProgress  && filteredItems.length > 0" style="text-align: end; margin-top: 25px;">
    <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" class="btn btn-info simpleRfIdColor sync-box-button-other"
      [disabled]="countToSync === 0 || isSyncInProgress"
      (click)="confirmToUpdateOnHand(null)" title="Items to sync: {{itemsToSync}}">Sync
      <span *ngIf="inventoryStorage.isFirstloaded">({{countToSync}})</span>
    </button>
    <button *ngIf="activeSourceTab == InventorySource.Mismatch" type="submit" class="btn btn-info simpleRfIdColor sync-box-button-other"
    [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
    [openDelay]="300" [closeDelay]="300" 
      (click)="openPrintModal()">Print
      <span *ngIf="inventoryStorage.isFirstloaded">({{countItemsToPrint}})</span>
    </button>
  </div>
</ng-container>
