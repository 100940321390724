import { ProgressBarService } from './progress-bar/progress-bar.service';
import { BaseHttpService } from './core/base-http.service';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { InventorySyncComponent } from './inventory-sync/inventory-sync-available/inventory-sync.component';
import { SyncHistoryComponent } from './sync-history/sync-history.component';
import { MappingFieldsComponent } from './mapping/mapping-fields.component';
import { InventorySyncService } from './inventory-sync/inventory-sync.service';
import { SyncHistoryService } from './sync-history/sync-history.service';
import { MappingService } from './mapping/mapping.service';
import { MappingComponent } from './mapping/mapping.component';
import { OrdersComponent } from './orders/orders.component';
import { OrdersService } from './orders/orders.service';
import { SetupWizardComponent } from './setup-wizard/setup-wizard.component';
import { FilterInnerLocationsPipe } from './setup-wizard/filter.inner-locations.pipe';
import { ErrorMessageService } from 'app/core/error-message.service';
import { SetupService } from 'app/setup-wizard/setup.service';
import { PrintingComponent } from './printing/printing.component';
import { PrintingService } from './printing/printing.service';
import { DeclineChargeComponent } from './decline-charge/decline-charge.component';
import { FilterPipe } from './filters/filter';
import { SettingsComponent } from './settings/settings.component';
import { UserSettingsInfoService } from './settings/settings.service';
import { VendLoginComponent } from './vend-login/vend-login.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { PurchaseOrdersService } from './purchase-orders/purchase-orders.service';
import { ButtonFilterPipe } from './filters/button-filter.pipe';
import { ProductsComponent } from './products/products.component';
import { ProductService } from './products/products.service';

import { NotifyModalComponent } from 'app/core/modal/notify-modal.component';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { PagerService } from 'app/core/PagerService';
import { ProductModalComponent } from 'app/printing-modal/product-modal.component';
import { UrlBuilderService } from 'app/core/url-builder';
import { PartialPrintModalComponent } from 'app/purchase-orders/partial-print-modal.component';
import { FulfilLoginComponent } from './fulfil-login/fulfil-login.component';
import { InventoryStorageService } from 'app/inventory-sync/inventory-storage.service';
import { ProgressBar } from 'app/progress-bar/progress-bar.component';
import { ProductPrintModalComponent } from './orders/product-print-modal.component';
import { InventoryPrintModalComponent } from '../app/inventory-sync/inventory-print-modal/inventory-print-modal.component';
import { InventoryOrderModalComponent } from '../app/inventory-sync/inventory-order-modal/inventory-order-modal.component';
import { HangFireService } from './core/hangfire/hangfire.service';
import { LicenseAgreementComponent } from './setup-wizard/license-agreement.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AuthConfigModule } from './auth-config.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { AppRoutingModule } from 'app/app-routing.module';
import AuthenticationModule from 'app/authentication/authentication.module';
import { InventorySyncOnHandComponent } from './inventory-sync/inventory-sync-on-hand/inventory-sync-on-hand.component';
import { ScrollingModule } from '@angular/cdk/scrolling'
import { PrintJobNotificationService } from './printing/print-job.notification.service';
import { NeverPrintedComponent } from './never-printed/never-printed.component';
import { NeverPrintedService } from './never-printed/never-printed.service';
import { NewerPrintedModalComponent } from './never-printed/never-printed-modal/never-printed-modal.component';
import { ImageHendlerComponent } from './image-hendler/image-hendler.component';
import { ChunkService } from './core/chunk-service';
import { LocalDatePipe } from './pipes/LocalDatePipe';


@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        InventorySyncComponent,
        SyncHistoryComponent,
        MappingFieldsComponent,
        MappingComponent,
        OrdersComponent,
        SetupWizardComponent,
        PrintingComponent,
        DeclineChargeComponent,
        FilterPipe,
        SettingsComponent,
        VendLoginComponent,
        PurchaseOrdersComponent,
        ButtonFilterPipe,
        ProductsComponent,
        NotifyModalComponent,
        ProductModalComponent,
        PartialPrintModalComponent,
        FulfilLoginComponent,
        ProgressBar,
        ProductPrintModalComponent,
        InventoryPrintModalComponent,
        InventoryOrderModalComponent,
        LicenseAgreementComponent,
        FilterInnerLocationsPipe,
        InventorySyncOnHandComponent,
        NeverPrintedComponent,
        NewerPrintedModalComponent,
        ImageHendlerComponent,
        LocalDatePipe,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AuthenticationModule,
        NgbModule,
        AppRoutingModule,
        AuthConfigModule,
        ScrollingModule,
    ],
    exports: [
        LocalDatePipe,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: navigator.language
        },
        ErrorMessageService,
        InventorySyncService,
        SyncHistoryService,
        MappingService,
        OrdersService,
        SetupService,
        UserSettingsInfoService,
        PurchaseOrdersService,
        ProductService,
        ErrorModalService,
        ConfirmModalService,
        BaseHttpService,
        PagerService,
        UrlBuilderService,
        InventoryStorageService,
        PrintingService,
        ProgressBarService,
        HangFireService,
        InventorySyncOnHandComponent,
        InventorySyncComponent,
        FilterPipe,
        ButtonFilterPipe,
        PrintJobNotificationService,
        NeverPrintedService,
        ChunkService,
        LocalDatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
