import { State } from "app/enums/printer-state"
import { LabelLayoutOrderField, OrderDirection } from "./enums"

export interface PrintJobViewModel {
  Id: number,
  Name: string,
  TasksCount: number,
  Status: string,
  ProductsCount: number,
  Type: number
}

export interface ILayoutRequest{
  orderBy: LabelLayoutOrderField,
  orderDirection: OrderDirection,
  customerId: number,
  deleted: boolean
}

export interface IStationRequest{
  vendorId: number,
  hasLocationAssigned: boolean
}

export interface IPrinterRequest{
  clientIds: string[]
}

export interface PrinterStateChanged {
  id?: string,
  status?: State,
  serialNumber?: string,
  clientId?: string,
  issueDescription: string,
  isNewPrintClient: boolean,
  isPrinterOnline?: boolean
}


