import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-vend-login',
    templateUrl: './vend-login.component.html',
    styleUrls: ['./vend-login.component.css'],
    standalone: false
})
export class VendLoginComponent implements OnInit {

  clientIdentity: string;
  @ViewChild('content', { static: true }) content: ElementRef;
  imageUrl: string;
  imageBlockClass: string;
  imageClass: string;
  titleClass: string;
  buttonClass: string;
  data: any;
  
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.imageUrl = "./assets/images/lightspeed-logo-white-v3.svg";
        this.imageBlockClass = "vend-icon-block";
        this.imageClass = "vend-icon";
        this.titleClass = "form-group";
        this.buttonClass = "vend-btn";
        //this.clientIdentity = localStorage.getItem("fulfilIdentity");

        let ngbModalOptions: NgbModalOptions = {
          backdrop : 'static',
          keyboard : false,
          centered: true
        };
    
        this.modalService.open(this.content, ngbModalOptions);
  }

  login() {
    window.location.href = 'https://secure.vendhq.com/connect?response_type=code&client_id=' + environment.vendClientId + '&redirect_uri=' + environment.vendRedirectUrl;
  }

}
