import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequiredChildsData } from 'app/core/required-childs-data';

@Component({
    selector: 'inventory-order-modal-component',
    template: `
    <div class="header-modal">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-scrollable-container">
            <div class="order-modal-main-box">
                <div class="order-modal-box">
                    <div class="order-modal-subbox1">
                        <b>Image:</b>
                    </div>
                    <div class="order-modal-subbox2">
                        <app-image-hendler [product]="product"></app-image-hendler>
                    </div>
                </div>
                <div class="order-modal-box">
                    <div class="order-modal-subbox1">
                        <b>Name:</b>
                    </div>
                    <div class="order-modal-subbox2">
                        {{product.Title}}
                    </div>
                </div>
                <div class="order-modal-box">
                    <div class="order-modal-subbox1">
                        <b>Audit:</b>
                    </div>
                    <div class="order-modal-subbox2">
                        {{date.toLocaleString()}}
                    </div>
                </div>
                <div class="order-modal-box">
                    <div class="order-modal-subbox1">
                        <b>Sku:</b>
                    </div>
                    <div class="order-modal-subbox2">
                        {{product.Sku}}
                    </div>
                </div>
                <div class="order-modal-box">
                    <div class="order-modal-subbox1">
                        <b>Total:</b>
                    </div>
                    <div class="order-modal-subbox2">
                        {{product.OrdersCount}}
                    </div>
                </div>
            </div>
            <table class="table col-md-12">
                <thead>
                    <tr class="col-md-12">
                        <th class="col-md-1">Order ID</th>
                        <th class="col-md-4">Order Date / Time</th>
                        <th *ngIf="data.isShopify" class="col-md-2">Channel</th>
                        <th class="col-md-3">Status</th>
                        <th class="col-md-1">Items</th>
                    </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let order of orders">
                            <td><a href="{{order.link}}" target="_blank" style="text-decoration: none;">{{order.Name}}</a></td>
                            <td>{{order.created_at}}</td>
                            <td *ngIf="data.isShopify">{{order.SourceName}}</td>
                            <td>{{order.status}}<span class="modal-statuses">{{order.fulfillment_status}}</span>
                            </td>
                            <td>
                                <div *ngFor="let item of order.line_items">
                                    <span *ngIf="data.isShopify && item.variant_id == product.VariantId">{{item.Quantity}}</span>
                                    <span *ngIf="data.isBigCommerce && item.product_id == product.ProductId">{{item.Quantity}}</span>
                                </div>
                            </td>
                        </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info cursor-pointer-btn" (click)="activeModal.close(false)">Close</button>
    </div>
    `,
    styleUrls: ['./inventory-order-modal.component.css'],
    standalone: false
})

  export class InventoryOrderModalComponent{
    @Input() product;
    @Input() orders;
    @Input() date;
    @Input() data: RequiredChildsData;

    constructor(public activeModal: NgbActiveModal) {}


  }