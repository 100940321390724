import { InventorySyncComponent } from '../inventory-sync/inventory-sync-available/inventory-sync.component';
import { Component, Input, OnInit } from '@angular/core';
import { SyncHistoryService } from './sync-history.service';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { ProgressBarDataModel } from '../progress-bar/progress-bar-data-model';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { HangFireService } from 'app/core/hangfire/hangfire.service';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { ProgressBarService } from 'app/progress-bar/progress-bar.service';
import { InventorySyncOnHandComponent } from 'app/inventory-sync/inventory-sync-on-hand/inventory-sync-on-hand.component';
import { InventorySyncHistory } from 'app/models/sync-history/classes';

@Component({
  selector: 'app-sync-history',
  templateUrl: './sync-history.component.html',
  styleUrls: ['./sync-history.component.css']
})
export class SyncHistoryComponent implements OnInit {
  loading = false;
  historyItems: Array<InventorySyncHistory> = [];
  compareDate: Date;
  revert = false;
  error = 'reverting the inventory.';
  pages: Array<number> = [];
  selectedPage = 1;
  fulfilSyncConfirmationIds : any;
  isReverted: boolean = false;
  isRevertInProgress: boolean = false;
  progressBarData: ProgressBarDataModel;
  revertedItem: any;
  currentInventoryComponent: any;
  currentSyncInventoryOperation: ActivityOperationType;

  @Input() data: RequiredChildsData;

  constructor(private syncHistoryService: SyncHistoryService, private errorModalService: ErrorModalService,
  public inventorySyncComponent: InventorySyncComponent, private confirmModalService: ConfirmModalService,
  private hangFireService: HangFireService, private progressBarService: ProgressBarService,
  public inventorySyncOnHandComponent: InventorySyncOnHandComponent) { }

  ngOnInit() {
      this.compareDate = new Date(new Date().setDate(new Date().getDate()-1));
      this.progressBarData = new ProgressBarDataModel();
      this.currentInventoryComponent = this.inventorySyncComponent.syncTab ?
                                       this.inventorySyncComponent : 
                                       this.inventorySyncOnHandComponent;
      this.currentSyncInventoryOperation = this.currentInventoryComponent.syncOperation;
      if(this.currentInventoryComponent.lastAuditDate ){
        this.compareDate = new Date(this.currentInventoryComponent.lastAuditDate);
      }
      
      this.progressBarData.locationExternalId = this.data.currentLocationExternalId;

      this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.RevertHistory).subscribe(res => {
        this.progressBarData.operationType = ActivityOperationType.RevertHistory;
        if(res){
          this.progressBarData.isTaskInProgress = res.IsInProgress;
          if(!res.IsInProgress && res.HangfireJobId){
            if(res.StatusJob == "Succeeded"){
              this.progressBarData.hangFireSuccess = true;
              this.progressBarData.hangfireJobId = res.HangfireJobId;
            }else if(res.StatusJob == "Failed"){
              this.progressBarData.hangFireFail = true;
              this.progressBarData.errorMessage = "last reverting inventory";
              this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarData.operationType).subscribe(r => {});
            }
          } else if(res.IsInProgress && res.HangfireJobId){
            if(res.StatusJob == "Processing"){
              this.isRevertInProgress = true;
            }
          }
        }
      });

      this.getReports(1);
  }

  ngDoCheck(){
    if(this.progressBarData.hangFireSuccess){
      this.progressBarData.hangFireSuccess = false;
      this.getRevertResult();
    } else if (this.progressBarData.hangFireFail){
      this.progressBarData.hangFireFail = false;
      this.isReverted = true;
      this.revertedItem.IsReverted = true;
      this.isRevertInProgress = false;
      this.loading = false;
    }
  }

  inventorySyncTab() {
    this.currentInventoryComponent.changeTab(this.isReverted);
  }

  getReports(page: number) {
    this.loading = true;
    this.syncHistoryService.getSyncHistory(
      page,
      this.data.currentLocationExternalId,
      this.currentSyncInventoryOperation
    ).subscribe(res => {
      this.historyItems = res.Entities;
      this.pages = res.Pages;
      this.selectedPage = page;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open("Error", 'loading reports');
    });
  }

  getDateFromString(date: string): Date{
    return new Date(date);
  }

  revertInventory() {
    this.progressBarData.operationType = ActivityOperationType.RevertHistory;
    this.isRevertInProgress = true;
    setTimeout(() => this.startProgressBar(), 1000)
    this.syncHistoryService.revertInventory(this.revertedItem.Id,
                                            this.data.currentLocationExternalId, 
                                            this.currentSyncInventoryOperation).subscribe(res => 
    {},
    err => {
      console.log(this.error);
      this.loading = false;
      this.errorModalService.open("Error", this.error);
    });
  }

  getRevertResult(){
    this.hangFireService.getHangFireJobResult(this.progressBarData.hangfireJobId, this.data.currentLocationExternalId, ActivityOperationType.RevertHistory).subscribe(res => {
      if(this.data.isFulfil){
        this.fulfilSyncConfirmationIds = res.FulfilSyncConfirmationIds; 
        this.progressBarData.isTaskInProgress = false;
        this.confirmModalService.open("Confirm", "Inventory adjustment request has been created. Please confirm in Fulfil (separate tab).", this.redirectToConfirmationFulfilSync.bind(this));
      }else{
        this.currentInventoryComponent.countToSync = 0;
      }
    });
    
    this.isReverted = true;
    this.revertedItem.IsReverted = true;
    this.isRevertInProgress = false;
    this.progressBarData.isTaskInProgress = false;
    this.loading = false;
  }

  private redirectToConfirmationFulfilSync(){
    window.open("https://" + this.data.shopName + ".fulfil.io/client/#/model/stock.inventory?filter=" + encodeURIComponent(this.fulfilSyncConfirmationIds), '_blank');
    this.ngOnInit();
  }

  finishRevert(){
    this.isRevertInProgress = false;
    this.progressBarData.isTaskInProgress = false;
    this.getReports(this.selectedPage);
  }

  confirmToRevert(item: any) {
    this.revertedItem = item;
    this.confirmModalService.open("Confirm", `Are you sure you want to revert this sync?`, this.revertInventory.bind(this));
  }

  startProgressBar(){
    this.progressBarData.isTaskInProgress = true;
  }

  checkAccessRevert(item: InventorySyncHistory){
    return new Date(item.Date) > this.compareDate && !item.IsReverted && !item.IsRevertReport
  }

  getSyncReport(item:InventorySyncHistory){
    this.syncHistoryService.getSyncReport(item.Id, false)
    .subscribe(res => {
      const link = document.createElement('a');
        link.target = '_blank';
        link.download = item.Id + '.csv';
        link.href = window.URL.createObjectURL(res);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    err => {
      console.log(err);
      this.errorModalService.open("Error", 'download reports');
    });
  }
}
