<div class="main-container container-fluid"  *ngIf='showContent'>

  <div class="header-container">
    <div class="logo-box">
      <div class="logo-box-1">
        <img class="logo" alt="SimpleRfid" src="./assets/images/internal-logo.svg" />
      </div>
      <div class="logo-box-2">
        <span>{{vendorName}}</span>
      </div>
    </div>

    <div class="label-box box">
      <div *ngIf="data.isAvailablePrint">
        <span class="locations-title">Printers</span>
        <select class="form-control locations-dropdown" [(ngModel)]="data.defaultPrinterId" (ngModelChange)="setDefaultPrinter()">
          <option value="" disabled="true"><span>Select printer...</span></option>
          <option *ngFor="let printer of data.printersForView" [value]="printer.id">
            {{printer.displayFullName}}
          </option>
        </select>
      </div>
    </div>

    <div class="print-icon-box box">
      <img *ngIf='data.isAvailablePrint && isPrinterOnline && !data.CurrentPrinter.SelectedPrinterHasIssue' height="40px"
        src="./assets/images/printerBlue.png" title="Printer connected">

      <img *ngIf='(!data.isAvailablePrint)' height="40px "
        src="./assets/images/printerUnavailable.png" title="This location is not currently configured to print">

        <img *ngIf='data.isAvailablePrint && !isPrinterOnline && !data.CurrentPrinter.SelectedPrinterHasIssue' height="40px "
        src="./assets/images/printerUnavailable.png" title="This printer is offline">

      <img *ngIf='data.isAvailablePrint && data.CurrentPrinter && data.CurrentPrinter.SelectedPrinterHasIssue' height="40px "
        src="./assets/images/printerHang.png" title="Please restart the printer">
    </div>

    <div class="label-box box">
      <div *ngIf="data.isAvailablePrint">
        <span class="locations-title">Label</span>
        <select class="form-control locations-dropdown" [(ngModel)]="defaultLabelId" (ngModelChange)="setDefaultLabel()">
          <option value="0" disabled="true"><span>Select label template...</span></option>
          <option *ngFor="let layout of data.PrintingLayouts" [value]="layout.id">
            {{layout.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="location-box box" *ngIf="!data.isBigCommerce">
        <span class="locations-title">Location</span>
        <select [disabled]='isContentDisabled' class="form-control locations-dropdown" [(ngModel)]="currentLocationName" (ngModelChange)="setLocation(currentLocationName)">
          <option value="null" disabled="true" [selected]="!currentLocationName"><span>Select Location...</span></option>
          <option *ngFor="let location of locations" [value]="location.Name">
            {{location.Name}}
          </option>
        </select>
    </div>

  </div>

  <div *ngIf="!!(errorMessageService?.error)"  class="row">
    <div class="col-md-12 bg-danger text-white">
      {{ errorMessageService?.error }}
    </div>
  </div>
  <div *ngIf="!!(errorMessageService?.warning)"  class="row">
    <div class="col-md-12 bg-warning text-white">
      {{ errorMessageService?.warning }}
    </div>
  </div>
  <div class="customTab">

    <nav ngbNav #tabs #nav="ngbNav" class="nav-tabs">
      @if(data.isShopify){
        <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
          <a ngbNavLink>
            <span class="fa fa-sync-alt"></span> Compare On Hand
          </a>
          <ng-template ngbNavContent>
            <app-inventory-sync-on-hand [data]="data"></app-inventory-sync-on-hand>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
          <a ngbNavLink>
            <span class="fa fa-history"></span> Auto Sync History
          </a>
          <ng-template ngbNavContent>
            <app-auto-sync-history [data]="data"></app-auto-sync-history>
          </ng-template>
        </ng-container>
      }

      <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fa fa-sync-alt"></span> 
          @if(data.isShopify){
            Compare Available (deprecated)
          } @else {
            Compare Available
          }
        </a>
        <ng-template ngbNavContent>
          <app-inventory-sync [data]="data"></app-inventory-sync>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem *ngIf="!data.isVend" [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <ul class="fa-ul order-tab-list-icon">
            <li><span class="fa-li orders-top-icon"><i class="far fa-xs fa-check-circle"></i></span></li>
            <li><span class="fa-li orders-bottom-icon"><i class="fas fa-xs fa-box"></i></span></li>
          </ul>
          @if(data.isShopify){
            <span class="order-tab-text">Orders & Returns</span>
          } @else {
            <span class="order-tab-text">Verify Open Orders</span>
          }
        </a>
        <ng-template ngbNavContent>
          <app-orders [data]="data"></app-orders>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem *ngIf="data.isFulfil" [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fa fa-file-alt"></span> Purchase
        </a>
        <ng-template ngbNavContent>
          <app-purchase-orders [data]="data"></app-purchase-orders>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem="printing-tab" [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fa fa-print"></span> Print
        </a>
        <ng-template ngbNavContent>
          <app-printing [data]="data"></app-printing>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fa fa-print"></span> Never Printed
        </a>
        <ng-template ngbNavContent>
          <app-never-printed [data]="data"></app-never-printed>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fas fa-tag"></span> Products
        </a>
        <ng-template ngbNavContent>
          <app-products [data]="data"></app-products>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [disabled]='isContentDisabled || isNotLocation'>
        <a ngbNavLink>
          <span class="fa fa-cog"></span> Settings
        </a>
        <ng-template ngbNavContent>
          <app-settings (onChanged)="onChanged($event)" [data]="data"></app-settings>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

<div class="footer">
  <span>
    (844) 637-2464&nbsp;<a href="mailto:support@simplerfid.com" target="_blank">support&#64;simplerfid.com</a>
  </span>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="full-width">
      <div class="simplerfid-icon-block">
          <img src="./assets/images/internal-logo.svg" class="logo" />
      </div>
      <div class="icon-block {{imageBlockClass}}">
        <img [src]="imageUrl" class="{{imageClass}}">
      </div>
      <div class="vend-sign-in">
        <h2>Sign in</h2>
      </div>
    </div>
    <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <h5 class="{{titleClass}}" id="modal-basic-title">You are logged out, please sign in</h5>
    <input *ngIf="data.isFulfil" type="text" class="form-control" placeholder="Type your Fulfil Account name" [(ngModel)]="clientIdentity" />
    <div class="form-group right">
      <button class="btn vend-btn {{buttonClass}}" (click)="loginVend()" [disabled]="!clientIdentity && data.isFulfil">Sign in</button>
    </div>
  </div>
</ng-template>
