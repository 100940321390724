export class SignalRHelper {
	public static getReconnectPolicy(): ReconnectPolicy {
		return {
			nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
				if (retryContext.previousRetryCount == 0) {
					return 1000;
				} else if (retryContext.previousRetryCount == 1) {
					return 2 * 1000;
				} else if (retryContext.previousRetryCount == 2) {
					return 5 * 1000;
				} else if (retryContext.previousRetryCount == 3) {
					return 10 * 1000;
				} else {
					return 30 * 1000;
				}
			},
		};
	}
}

interface ReconnectPolicy {
	nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null;
}

interface RetryContext {
	previousRetryCount: number;
	elapsedMilliseconds: number;
	retryReason: Error;
}