import { OrderTypes } from './../core/url-builder';
import { Component, OnInit, Input } from '@angular/core';
import { PurchaseOrdersService } from './purchase-orders.service';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PartialPrintModalComponent } from './partial-print-modal.component';
import { RequiredChildsData } from './../core/required-childs-data';
import { BaseHttpService } from 'app/core/base-http.service';
import { PrintingService } from 'app/printing/printing.service';
import { UrlBuilderService } from '../core/url-builder';
import { PurchaseOrder } from 'app/models/purchseOrders/interfaces';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})


export class PurchaseOrdersComponent implements OnInit {
  loading = false;
  orders: Array<PurchaseOrder> = [];
  pages: Array<any> = [];
  selectedPage = 1;
  searchText = '';
  type = this.purchseOrdersService._purchaseType;
  status = 'SENT';
  allSelected = false;
  action = '';
  printButtonEnabled: boolean;
  isLoadNext = true;
  isLoadNextDisabled = false;
  lastOrdersVersion: number;
  firstOrdersVersion: number;
  ordersLimit = 50;
  isSotedByDateAscending = false;
  isPurchaseTab = true;
  page = 1;
  printingOrdersError = 'printing orders';
  searchKeyword = '';
  ordersStatuses: Array<string> = [];
  orderType: string;
  orderLinkType: OrderTypes = OrderTypes.Purchase;
  isChekedOrder: boolean;
  isFulfilPurchase: boolean;


  isOrderInfo = true;

  ngbModalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop : 'static',
    keyboard : false,
    centered: true
  };

  @Input() data: RequiredChildsData;

  constructor(public purchseOrdersService: PurchaseOrdersService,
    private errorModalService: ErrorModalService, private modalService: NgbModal, public baseService: BaseHttpService,
    private PrintingService: PrintingService, private urlBuilder: UrlBuilderService) { }

  ngOnInit() {
    this.loading = true;
    this.orderType = "purchase";
    this.isFulfilPurchase = this.data.isFulfil && this.isPurchaseTab;
    this.purchseOrdersService.getOrderStatuses(this.orderType).subscribe(res =>{
      this.ordersStatuses = res;
      this.status = "";
      this.getOrders();
    });
  }

  getOrders() {
    this.loading = true;
    if(this.searchKeyword != ''){
      this.status = undefined;
    }
    var lastOrFirsVersion = this.isLoadNext ? this.lastOrdersVersion : this.firstOrdersVersion;
    this.purchseOrdersService.getOrders(this.status, this.searchKeyword, this.type, lastOrFirsVersion, this.isLoadNext, this.data.currentLocationExternalId)
    .subscribe(res => {
      if(res.orders.length > 0){
        this.orders = res.orders;
        this.isSotedByDateAscending = false;
        this.sortByDate();
        if (res.orders.length > 0) {
          this.firstOrdersVersion = res.min;
          this.lastOrdersVersion = res.max;
        }
        if (res.orders.length < this.ordersLimit) {
          this.isLoadNextDisabled = true;
        }

        this.orders.forEach(o => {
          o.Link = this.urlBuilder.getUrltoOrder(o.Id, this.data, this.orderLinkType);
        });
      } else {
        this.orders = res.orders;
      }
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open("Error", 'when loading orders');
    });
  }

  getReturn() {
    this.type = this.purchseOrdersService._returnType;
    this.getFilteredOrders();
  }

  getPurchase() {
    this.type = this.purchseOrdersService._purchaseType;
    this.getFilteredOrders();
  }

  getFilteredOrders() {
    this.searchKeyword = '';
    this.page = 1;
    this.isLoadNext = true;
    this.isLoadNextDisabled = false;
    this.lastOrdersVersion = 0;
    this.firstOrdersVersion = 0;
    this.getOrders();
  }

  loadNext() {
    this.isLoadNext = true;
    this.getOrders();
    this.page++;
  }

  loadPrevious() {
    this.isLoadNextDisabled = false;
    this.isLoadNext = false;
    this.getOrders();
    this.page--;
  }

  isPrintable(order) {
    if (order.Status.toLowerCase() == "received" || order.Status.toLowerCase() == "sent" || order.Status.toLowerCase() == "waiting" || this.orderType == 'transfer') {
      return true;
    }
    return false;
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    this.orders.forEach(order => {
      if (this.isPrintable(order))
        order.Selected = this.allSelected;
    });
    this.isAnyChecked();
  }

  select() {
    this.isAnyChecked();
  }

  private isAnyChecked() {
    this.isChekedOrder = this.orders.some((order) => order.Selected);
    this.printButtonEnabled = this.data.isAvailablePrint && this.isChekedOrder;
  }

  private getSelectedOrders() {
    return this.orders.filter(function(order) { return order.Selected; });
  }

  submit(isPrint: boolean) {
    const orders = this.getSelectedOrders();
    this.loading = true;
    const submittedOrders = [];
    this.purchseOrdersService.submitPurchaseOrders(orders, this.data.currentLocationExternalId).subscribe(res => {
      var emptyOrders = [];
      orders.forEach(function(orderVm) {
        const order = res.find(o => o.Id === orderVm.Id );

        if (order) {
          if (!order.OrderId || order.OrderId == 0) {
            emptyOrders.push(order.Name);
          }
          orderVm.Status = order.Status;
          orderVm.MerchId = order.MerchId;
          submittedOrders.push(orderVm);
        }
        orderVm.Selected = false;
      });

      if (emptyOrders.length < 1) {
          //this.PrintOrders(orders);
      } else {
        this.errorModalService.open("Error", `when submitting orders. Order(s): "${emptyOrders.join(', ')}" is(are) empty.`);
      }

      this.loading = false;
    }, err => {
      this.loading = false;
      this.errorModalService.open("Error", 'when submitting orders');
    });
  }

  private PrintOrders(orders) {
    const orderIds = orders.map(function (order) { return order.Id; });
    const productIds = [];
    this.PrintingService.printOrders(orderIds, this.data, true, productIds).subscribe(res => {
      this.PrintingService.goToPrintTab();
    },
    err => {
      if (err._body) {
        var body = JSON.parse(err._body);
        var additionalMessage = "";

        if (body.ExceptionMessage)
          additionalMessage = body.ExceptionMessage;
        else
          additionalMessage = err;

        this.errorModalService.open("Error", this.printingOrdersError + additionalMessage);
      }
      else
        this.errorModalService.open("Error", this.printingOrdersError);
    });
  }

  openPartialPrintModal(order, isInfo : boolean) {
    this.purchseOrdersService.submitPurchaseOrders([order], this.data.currentLocationExternalId).subscribe(res => {
      var emptyOrders = [];

      if (res[0]) {
        if (!res[0].OrderId || res[0].OrderId == 0) {
          emptyOrders.push(res[0].Name);
        }
      }

      var submittedOrder = res.find(o => o.Id == order.Id);
      if (emptyOrders.length < 1) {
        this.getProducts(submittedOrder, isInfo);
      } else {
        if(!isInfo){
          this.errorModalService.open("Error", `when submitting orders. Order(s): "${emptyOrders.join(', ')}" is(are) empty.`);
        }else {
          this.errorModalService.open("Error", "No Products");
        }
      }
    }, err => {
      this.errorModalService.open("Error", 'when submitting orders');
    });
  }

  sortByDate(){
    this.orders = this.orders.sort((a, b) => this.isSotedByDateAscending ? this.sort(a, b) : this.sort(b, a));

    this.isSotedByDateAscending = !this.isSotedByDateAscending;
  }

  private sort(a, b){
    return new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime();
  }

  openPartialInfoModal(order){
    this.openPartialPrintModal(order, this.isOrderInfo);
  }

  getProducts(submittedOrder, isInfo) {
    this.purchseOrdersService.getPurchaseOrderProducts(submittedOrder.OrderId).subscribe(result => {
      console.log("open partial print modal window");
      const modalRef = this.modalService.open(PartialPrintModalComponent, this.ngbModalOptions);
      modalRef.componentInstance.isInfo = isInfo;
      modalRef.componentInstance.products = result;
      modalRef.componentInstance.order = submittedOrder;
      modalRef.componentInstance.data = this.data;

    }, err => {
      this.errorModalService.open("Error", 'when trying to get products from order');
    });
  }

  bulkAction() {
    this[this.action]();
    this.action = '';
    this.allSelected = false;
  };

  submitFulfilPurchase(isTransfer: boolean = false) {
    this.loading = true;
    const orders = this.getSelectedOrders();
    if(isTransfer){
      orders.forEach(o => o.SourceOutlet = o.Outlet);
    } else {
      orders.forEach(o => o.SourceOutlet = null);
    }
    const submittedOrders = [];
    this.purchseOrdersService.submitPurchaseOrders(orders, this.data.currentLocationExternalId).subscribe(res => {
      var emptyOrders = [];
      orders.forEach(function(orderVm) {
        const order = res.find(o => o.Id === orderVm.Id );

        if (order) {
          if (!order.OrderId || order.OrderId == 0) {
            emptyOrders.push(order.Name);
          }
          orderVm.Status = order.Status;
          orderVm.MerchId = order.MerchId;
          submittedOrders.push(orderVm);
        }
        //orderVm.Selected = false;
      });

      this.orders = this.orders.filter(o => !o.Selected);

      if (emptyOrders.length > 0) {
        this.errorModalService.open("Error", `when submitting orders. Order(s): "${emptyOrders.join(', ')}" is(are) empty.`);
      }

      this.loading = false;
    }, err => {
      this.loading = false;
      this.errorModalService.open("Error", err.message);
    });
  }

  public print(){
    const orders = this.getSelectedOrders();
    this.PrintOrders(orders);
  }

  public submitAsTransfer(){
    this.submitFulfilPurchase(true);
  }
}
