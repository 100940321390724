import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class InventorySyncService extends BaseHttpService {

  EnqueuedItem(visible: boolean, locationExternalId: string, url: string): Observable<any> {
    let requestStr = url + "?";

    if (locationExternalId) {
      requestStr += 'locationExternalId=' + locationExternalId + '&';
    }

    if (visible) {
      requestStr += 'isVisible=' + visible + '&';
    }

    return this.get(requestStr);
  }

  enqueuedUpdateItem(data: any, locationExternalId: string, url: string): Observable<any> {
    if (locationExternalId) {
      data.ExternalLocationId = locationExternalId;
    }
    return this.post(url, data);
  }

  getSyncState(data: any): Observable<any> {
    var requestParam = '';

    if(data.currentLocationExternalId && !data.isBigCommerce){
      requestParam += '?locationExternalId=' + data.currentLocationExternalId;
    }

    return this.get(urls.getSyncState + requestParam);
  }

  setSyncStateToFalse(locationExternalId: string){
    return this.post(urls.setSyncStateToFalse + '?locationExternalId=' + locationExternalId, {});
  }

  getLastAuditDate(locationExternalId): Observable<any> {
    let requestStr = urls.getLastAuditDate + "?" + 'locationExternalId=' + locationExternalId + '&';
    return this.get(requestStr);
  }

  getLastScanDate(locationExternalId): Observable<any> {
    let requestStr = urls.getLocalScanDate + "?" + 'locationExternalId=' + locationExternalId + '&';
    return this.get(requestStr);
  }

  getLastSyncDate(locationExternalId): Observable<any> {
    let requestStr = urls.getLocalSyncDate + "?" + 'locationExternalId=' + locationExternalId + '&';
    return this.get(requestStr);
  }

  getScans(locationExternalId): Observable<any> {
    let requestStr = urls.getScans + "?" + 'locationExternalId=' + locationExternalId + '&';
    return this.get(requestStr);
  }

  loadItemsOnHand(locationExternalId: string): Observable<any> {
    let requestStr = urls.shopify.loadInventoryItemOnHand + "?" + 'locationExternalId=' + locationExternalId + '&';
    return this.get(requestStr);
  }

  getItemsOnHand(locationExternalId: string){
    let requestStr = urls.shopify.getInventoryItemOnHand + "?" + 'locationExternalId=' + locationExternalId;
    return this.get(requestStr);
  }
}
