import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';

import { MappingService } from './mapping.service';

@Component({
    selector: 'app-mapping-fields',
    templateUrl: './mapping-fields.component.html',
    styleUrls: ['./mapping-fields.component.css'],
    standalone: false
})
export class MappingFieldsComponent implements OnInit {
  loading = false;
  isValid = true;
  @Output() savedEvent = new EventEmitter();
  options: Array<any> = [];
  names: Array<any> = [];
  mapping = {};
  errors = {};

  constructor(private mappingService: MappingService) { }

  ngOnInit() {
    this.loading = true;
    this.mappingService.getMappingData().subscribe(res => {
      this.options = res.options;
      this.names = res.names;
      this.mapping = res.map;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
    });
  }

  private validateMapping() {
    let res = true;
    if (!this.mapping['Description']) {
      res = false;
      this.errors['Description'] = 'This field is required';
    }

    if (!this.mapping['SubDescription']) {
      res = false;
      this.errors['SubDescription'] = 'This field is required';
    }

    this.isValid = res;

    if (res) {
      this.errors = {};
    }

    return res;
  }

  @Output()
  save() {
    if (this.validateMapping()) {
      this.mappingService.saveMappingData(this.mapping).subscribe(res => {
        this.savedEvent.emit(true);
      },
      err => {
        console.log(err);
        this.savedEvent.emit(false);
      });
    } else {
      this.savedEvent.emit(false);
    }
  }

}
