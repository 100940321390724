import { Component, DoCheck, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { ProgressBarDataModel } from '../progress-bar/progress-bar-data-model';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { HangFireService } from 'app/core/hangfire/hangfire.service';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { ProgressBarService } from 'app/progress-bar/progress-bar.service';
import { AutoSyncHistoryService } from './auto-sync-history.service';
import { catchError, debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { LayoutFormatType } from 'app/printing/printing.service';
import { UserSettingsInfoService } from 'app/settings/settings.service';

@Component({
    selector: 'app-auto-sync-history',
    templateUrl: './auto-sync-history.component.html',
    styleUrls: ['./auto-sync-history.component.css'],
    standalone: false
})
export class AutoSyncHistoryComponent implements OnInit, DoCheck, OnDestroy {
  loading = false;
  loadingInBody: boolean;
  historyItems: AutoSyncHistoryOperation[] = [];
  historyItemsDic: { [key: string]: AutoSyncHistoryOperation[] } = {};
  sortedHistoryItems: { key: string; value: AutoSyncHistoryOperation[];}[]
  compareDate: Date;
  pages: Array<number> = [];
  selectedPage = 1;
  progressBarData: ProgressBarDataModel;
  currentInventoryComponent: any;
  currentSyncInventoryOperation: ActivityOperationType;
  shopifyPage: number = 1;
  searchKeyword: string = '';
  toTopButtonVisible = false;
  pageButtonBlock: boolean;
  settings: any = {};

  public searchTrigger$: Subject<void> = new Subject<void>();
  private _searchTriggerSubscribe: Subscription;
  searchPlaceholder: string = 'Search by Name, Sku...';

  @Input() data: RequiredChildsData;

  constructor(
    private errorModalService: ErrorModalService,
    private confirmModalService: ConfirmModalService,
    private autoSyncHistoryService: AutoSyncHistoryService,
    private userSettingsService: UserSettingsInfoService) { 
          this._searchTriggerSubscribe = this.searchTrigger$.pipe(
            debounceTime(1000)
          ).subscribe(res => {
            this.getHistory(1);
          });
         }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  async ngOnInit() {
    this.loading = true;
    let userSettings = await this.userSettingsService.getUserSettingsInfo();
    this.settings = userSettings;
    this.data.AutoReceiveApply = this.settings.AutoReceiveApply;
    this.data.AutoTransferApply = this.settings.AutoTransferApply;
    this.getHistory(1);
  }

  ngDoCheck(){}

  getHistory(page: number): void {
    this.shopifyPage = page;
    this.pageButtonBlock = false;

    this.loadingInBody = true;

    this.autoSyncHistoryService.getHistory(page, this.searchKeyword)
    .pipe(
      catchError(err => {
        console.log(err.message);
        this.errorModalService.open("Error", err.message);
        this.loadingInBody = false;
        this.loading = false;
        throw new Error(err.message);
      })
    )
    .subscribe(res => {
      this.historyItems = res.AutoSyncOperationModels;
      this.pageButtonBlock = this.searchKeyword != '' || !res.NextPage;
      this.loadingInBody = false;
      this.loading = false;
      this.historyItemsDic = this.historyItems.reduce<{[key: string]: AutoSyncHistoryOperation[]}>((acc, cur) => {
        let date = cur.CreateDate.toString();
        (acc[date] = acc[date] || []).push(cur);
        return acc;
      }, {});

      this.sortedHistoryItems = Object.keys(this.historyItemsDic)
      .sort((a, b) => {
        return new Date(b).getTime() - new Date(a).getTime();
      }).map(key => {
        return { key, value: this.historyItemsDic[key] };
      });
    });
  }

  refresh(): void {
    this.getHistory(1);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    this.toTopButtonVisible = scrollPosition > 260;
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  applyChanges(item: AutoSyncHistoryOperation): void {
    this.loadingInBody = true;
    const data = [item];
    this.applyChangesSession(data);
  }

  applyChangesSession(operations?: AutoSyncHistoryOperation[]): void {
    if(operations?.length > 0){
      operations = operations.filter(o => o.Status == AutoSyncOperationStatus.Waiting);
      this.autoSyncHistoryService.apllyAutoSyncChanged(operations)
      .pipe(
        catchError(err => {
          console.log(err.message);
          this.errorModalService.open("Error", err.message);
          this.loadingInBody = false;
          this.loading = false;
          throw new Error(err.message);
        })
      )
      .subscribe(res => {
        this.setStatusAndQuantityOnHand(res);
        this.loadingInBody = false;
        this.loading = false;
      });
    } else {
      this.errorModalService.open("Error", "Operations not found");
    }
  }

  showApplyButton(operation: AutoSyncHistoryOperation): boolean {
    return operation.Status == AutoSyncOperationStatus.Waiting;
  }

  showApplySessionButton(obj: { key: string; value: AutoSyncHistoryOperation[];}): boolean {
    return obj.value.some(v => v.Status == AutoSyncOperationStatus.Waiting);
  }

  getOperationType(obj: { key: string; value: AutoSyncHistoryOperation[];}) : AutoSyncOperationType {
    return obj.value.find(v => v.Type).Type;
  }

  private setStatusAndQuantityOnHand(items: AutoSyncOperationApplyModel[]): void {
    items.forEach(item => {
      const targetItem = this.historyItems.find(i => i.Id === item.Id);
    if (targetItem) {
      targetItem.Status = item.Status;
      targetItem.Details.forEach(d => {
        const matchingDetail = item.Details.find(itemDetail => itemDetail.Id === d.Id);
        if (matchingDetail) {
          d.NewOnHandQuantity = matchingDetail.NewOnHandQuantity;
        }
      });
    }
    });
  }

  updateCheckbox(){
    this.userSettingsService.updateUserSettingsInfo(this.settings)
    .pipe(
      catchError(err => {
        console.log(err);
        this.errorModalService.open("Error", 'updating the settings');
        throw new Error(err.message);
      })
    )
    .subscribe(res => {
      this.data.AutoReceiveApply = this.settings.AutoReceiveApply;
      this.data.AutoTransferApply = this.settings.AutoTransferApply;
    });
  }
}

export interface AutoSyncOperationModelResponse{
  AutoSyncOperationModels: AutoSyncHistoryOperation[],
  Total: number,
  NextPage: boolean
}

export interface AutoSyncHistoryOperation {
    Id: number,
    CreateDate: Date,
    ProductTitle: string,
    ImageUrl: string,
		ThumbnailUrl: string,
    Status: AutoSyncOperationStatus,
    Type: AutoSyncOperationType,
    InventoryItemId: number,
    Details: AutoSyncHistoryOperationDetails[]
}

export interface AutoSyncHistoryOperationDetails {
    Id: number,
    AutoSyncOperationsId: number,
    LocationId: number,
    LocationName: string,
    Adjustment: number,
    NewOnHandQuantity: number
}

export interface AutoSyncOperationApplyModel {
  Id: number,
  Status: AutoSyncOperationStatus,
  Details: AutoSyncHistoryOperationDetails[]
}

export interface AutoSyncOperationDetailsApplyModel {
  Id: number,
  NewOnHandQuantity: number
}

export enum AutoSyncOperationStatus {
	Waiting = "Waiting",
  Processing = "Processing",
	Applied = "Applied",
  Failed = "Failed"
}

export enum AutoSyncOperationType {
	Receive = "Receive",
  Transfer = "Transfer"
}