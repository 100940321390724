export const urls = {
    refreshTokenUrl: 'authorization/RefreshToken',

    getAvailableVendorsUrl: 'settings/getAvailableVendors',
    getLocationsInfoUrl: 'settings/GetLocations',
    // saveSourceUrl: 'settings/SaveSource',
    saveLocationsUrl: 'settings/SaveLocations',
    initialConfig: 'settings/InitailConfirutation',
    isInVendorChangeState: 'settings/GetChangeVendorState',
    getPurchaseOrdersUrl: 'purchaseOrder/Get',
    submitPurchaseOrders: 'purchaseOrder/SubmitPurchaseOrders',
    getOrdersStatuses: 'purchaseOrder/GetOrdersStatusesList',
    getPurchaseOrdersProducts: 'purchaseOrder/GetProducts',

    getOrdersUrl: 'order/Get',
    submitOrdersForQcUrl: 'order/Submit',
    getOrdersPdfUrl: 'order/GetPdf',
    getOrderItemsUrl: 'order/GetOrderItems',


    getSyncHistoryUrl: 'history/GetSyncHistory',
    getSyncReportUrl: 'history/GetSyncReport',


    getMappingUrl: 'settings/getMapping',
    saveMappingUrl: 'settings/saveMapping',

    enqueuedLoadItemUrl: 'inventory/EnqueuedLoadItem',
    enqueuedLoadNeverPrinted: 'inventory/EnqueuedLoadNeverPrinted',
    getInventoryUrl: 'inventory/CurrentInventory',
    getNeverPrintedUrl: 'inventory/GetNeverPrinted',
    enqueuedUpdateItemUrl: 'inventory/EnqueuedUpdateItem',
    getUpdateResultUrl: 'inventory/GetUpdateResult',
    enqueuedRevertItemUrl: 'inventory/EnqueuedRevertItem',
    getRevertResultUrl: 'inventory/GetRevertResult',
    reportsCount: 'inventory/GetReportsCount',
    getLastAuditDate: 'inventory/GetLastAuditDate',
    getLocalScanDate: 'inventory/GetLastScanDate',
    getLocalSyncDate: 'inventory/GetLastSyncDate',

    printOrders: 'print/PrintOrders',
    partialPrintOrderUrl: 'print/PartialPrintOrder',
    printProductUrl: 'print/PrintProduct',
    getProductsUrl: 'product/getProducts',
    copyProductsUrl: 'product/CopyProducts',
    setVisibility: 'product/setvisibility',
    setInventoriTracking: 'product/setInventoryTracking',
    searchByKeywordUrl: 'product/SearchProductsByKeyword',
    copyUpdateAllProducts: 'product/CopyAll',
    ModifyUniqueField: 'product/ModifyUniqueField',
    RevertModifyAllUniqueField: 'product/RevertModifyAllUniqueField',


    getHangFireJobStatus: 'jobs',
    getHangFireJobResult: 'jobs',


    getPrintStautsUrl: 'print/GetStatus',
    getPrintStautsesUrl: 'print/GetStatuses',
    getPrinterNameUrl: 'print/GetPrinterName',
    getPrintHistoryUrl: 'print/GetPrintHistory',
    createPrintTagRequest: 'print/TagRequest',
    cancelPrintTagRequest: 'print/CancelTagRequest',

    getUserSettingsInfo: 'userSettings/GetSettings',
    getSubLocations: 'fulfil/GetFulfilSubLocations',
    setSubLocation: 'fulfil/SetFulfilSubLocations',
    updateUserSettingsInfo: 'userSettings/UpdateSettings',
    changeOpenOrdersAgeDays: 'userSettings/ChangeOpenOrdersAgeDays',
    getLicenseAgreement: 'userSettings/GetLicenseAgreement',
    getShopInfo: 'userSettings/GetShopInfo',
    changeVendor: 'userSettings/SetChangeVendorState',

    getLocationsUrl: 'location/Get',
    getAllLocationsUrl: 'location/GetAll',
    getLocationUrl: 'location/Get',

    fulfilRefreshToken: 'fulfil/RefreshAccessToken',
    getBindLocationsUrl: 'location/GetSimplerfIdLocations',
    bindLocationUrl: 'userSettings/BindPartnerLocation',
    unBindLocationUrl: 'userSettings/UnBindPartnerLocation',

    printProducts: 'Print/PrintProducts',
    searchProductsUrl: 'Print/SearchProducts',

    setSyncStateToFalse: 'progressBar/CancelActivity',
    getSyncState: 'progressBar/GetTaskState',
    deleteHangFireJobId: 'progressBar/DeleteJobId',
    getScans: 'shopify/GetScans',

    shopify: {
        loadInventoryItemOnHand:'shopify/LoadInventoryItemOnHand',
        getInventoryItemOnHand: 'shopify/GetInventoryItemOnHand',
        enqueuedUpdateOnHandItemUrl: 'shopify/EnqueuedUpdateOnHandItem',
        enqueuedRevertOnHandItemUrl: 'shopify/EnqueuedRevertOnHandItem',
        getLocalLastAuditDate: 'shopify/GetLocalLastAuditDate',
        setOrderSubmit: 'shopify/SetSubmitOrder',
        getOrderItems: 'shopify/GetOrderItems',
        getRefundOrders: 'shopify/GetRefundOrders',
        initConfig: 'shopify/InitailConfirutation',
        webHook: {
            getWebHook: 'shopify/IsWebHooksEnabled',
            subscribe: {
                createAndUpdateProduct: 'shopify/SubscribeToProductCreateAndUpdateWebHook',
                createOrder: 'shopify/SubscribeToOrderCreateWebHook'
            },
            unsubscribe: {
                createAndUpdateProduct: 'shopify/UnsubscribeFromProductCreateAndUpdateWebHook',
                createOrder: 'shopify/UnsubscribeFromOrderCreateWebHook'
            }
        },
        getAutoSyncHistoryUrl: 'shopify/GetAutoSyncHistory',
        applyShopifyAutoSyncOperations: 'shopify/ApplyShopifyAutoSyncOperations',
    },

    bigCommerce: {
        getItems: 'bigCommerce/GetOrderItemsByUrl',
        initConfig: 'bigCommerce/InitailConfirutation',
        webHook: {
            getWebHook: 'bigCommerce/IsWebHooksEnabled',
        subscribe: {
            createAndUpdateProduct: 'bigCommerce/SubscribeToProductCreateAndUpdateWebHook',
            createOrder: 'bigCommerce/SubscribeToOrderCreateWebHook'
        },
        unsubscribe: {
            createAndUpdateProduct: 'bigCommerce/UnsubscribeFromProductCreateAndUpdateWebHook',
            createOrder: 'bigCommerce/UnsubscribeFromOrderCreateWebHook'
        }
        }
    },

    fulfil: {
        initConfig: 'fulfil/InitailConfirutation'
    },

    vend: {
        initConfig: 'vend/InitailConfirutation'
    }
};
