import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageService } from 'app/core/error-message.service';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { SetupService } from "app/setup-wizard/setup.service";
import { UserSettingsInfoService } from "../settings/settings.service";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LicenseAgreementComponent } from './license-agreement.component';
import { AuthService } from 'app/authentication/services/auth.service';
import { LoginResponse } from 'angular-auth-oidc-client';
import { catchError, filter, map, share, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, of, throwError } from 'rxjs';
import { NavigationService } from 'app/services';
import { Constants } from 'app/constants/constants';
import { 
  IExternalLocationEditViewModel,
  ILocationViewModel,
  IOuterLocations,
  ISimpleRfidLocation,
  IVendorModel,
  SimpleRfidLocations,
  LocationType
} from 'app/models/setup';
import { ErrorModalService } from 'app/core/modal/error-modal.service';

@Component({
    selector: 'app-setup-wizard',
    templateUrl: './setup-wizard.component.html',
    styleUrls: ['./setup-wizard.component.css'],
    standalone: false
})
export class SetupWizardComponent implements OnInit, OnDestroy {
  step = 'intro';
  public saveAvailable = false;
  loading = false;
  fromMapping = false;
  platform = '';
  isProductCopy = false;
  isInVendorChangeState: boolean = false;
  data: RequiredChildsData;
  public email: string;
  isBarcodeProduct: boolean = false;
  CanUseBarcodeAsKeyField: boolean;
  currentVendor: number;
  saveLocationsAction: boolean = false;

  ngbModalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    centered: true
  };

  private subscriptions: Subscription = new Subscription();
  public checkAuth$: Observable<LoginResponse>;
  public vendors$: Observable<IVendorModel[]>;
  public selectedVendor$: BehaviorSubject<IVendorModel | undefined> = new BehaviorSubject<IVendorModel | undefined>(undefined);
  public innerLocations: ILocationViewModel[] = [];
  public externalLocations: IExternalLocationEditViewModel[] = [];

  constructor(public errorMessageService: ErrorMessageService,public setupService: SetupService,
     private settingsService: UserSettingsInfoService, private modalService: NgbModal,
     private authService: AuthService,
     private navigationService: NavigationService,
         private errorModalService: ErrorModalService) {
      this.data = new RequiredChildsData();
     }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.loading = true;

    this.checkAuth$ = this.authService.checkAuth()
      .pipe(
        tap(_ => {
          this.email = _.userData.email;
        }),
        share()
      );

    this.vendors$ = this.checkAuth$.pipe(
      filter(_=>_.isAuthenticated),
      switchMap(_=> {
        return this.setupService.getAvailableVendors(this.email);
      }),
      map(_=> {
        if (_.Vendors.length > 1) {
          return [{
            Id: -1,
            Name: 'Please select vendor'
          },
          ..._.Vendors
          ];
        }

        return _.Vendors;        
      }),
      tap(_ => {
        if(!this.selectedVendor$.value) {
          this.selectedVendor$.next(_[0]);
        }
      })
    );

    const externalLocationsSubscripion = this.selectedVendor$.pipe(
      filter(vendor => vendor != undefined),
      switchMap(_ => {
        if(_.Id < 1) {
          const emptyResult: SimpleRfidLocations = {
            DistributionCenters: [],
            ExternalLocations: [],
            Stores: [],
            CanUseBarcodeAsKeyField: false,
            CredentialsValid: true,
            Message: ''
          };
          return of(emptyResult);
        }
        this.currentVendor = _.Id;

        return this.setupService.getLocations(_.Id)
        .pipe(
          catchError(err =>{
            this.errorModalService.open("Error", err.message);
            throw new Error(err);
          })
        );
      }),
      tap(_=> {
        this.innerLocations = this.buildInnerLocationsList(_);
        this.CanUseBarcodeAsKeyField = _.CanUseBarcodeAsKeyField;
      }),
      map(_=> {
        return _.ExternalLocations.map<IExternalLocationEditViewModel>(el => {
          return {
            name: el.Name,
            locationType: LocationType.DC,
            externalId: el.ExternalId,
            selectedLocation: this.innerLocations[0]
          };
        })
      }),
    ).subscribe(_ => {
      this.externalLocations = _;
    });

    this.subscriptions.add(externalLocationsSubscripion);

    let result = await this.setupService.getLicenseAgreement();
    let isLicenseAgreement = result.AgreeToUseMyData && result.AgreeToUseCustomerData;
    this.data.agreeUseMyData = result.AgreeToUseMyData;
    this.data.agreeUseCastomerData = result.AgreeToUseCustomerData;
    try{
      const shopInfo = await this.setupService.getShopInfoAsync();
      this.data.platform = shopInfo.Platform;
      this.platform = shopInfo.Platform;
    } catch(e) {
      console.log(e);
    }
    if(this.platform == "Shopify" && !isLicenseAgreement){
      const modalRef = this.modalService.open(LicenseAgreementComponent, this.ngbModalOptions);
      modalRef.componentInstance.data = this.data;
    }
    this.loading = false;
  }

  public goSignOut() {
    this.authService.signOut().subscribe();
  }

  private buildInnerLocationsList(_: SimpleRfidLocations): ILocationViewModel[] {
    const sortLocationsByName: (a: ILocationViewModel, b: ILocationViewModel) => number = (a, b) => {
      if (a.id == Constants.Locations.NotSelected) {
        return -1;
      }
      if (b.id == Constants.Locations.CreateNew) {
        return 1;
      }
      return a.name > b.name ? 1 : -1;
    };

    const buildLocationViewModel = (location: ISimpleRfidLocation, type: LocationType): ILocationViewModel => {
      return {
        id: location.Id,
        name: location.Name,
        locationType: type,
      };
    }

    return [ {
      id: Constants.Locations.NotSelected,
      name: 'Please select location',
      locationType: LocationType.DC,
    },
    ..._.DistributionCenters
      .map(dc => buildLocationViewModel(dc, LocationType.DC))
      .sort(sortLocationsByName),
    ..._.Stores
      .map(store => buildLocationViewModel(store, LocationType.Store))
      .sort(sortLocationsByName)
  ];
  }

  //Workaround to call pipe logic
  public rebuildInnerLocations() {
    this.innerLocations = [...this.innerLocations];

    this.disadledFinishButton();
  }

  public resetSelectedLocation(location: IExternalLocationEditViewModel) {
    location.selectedLocation = this.innerLocations[0];

    this.rebuildInnerLocations();
  }

  private disadledFinishButton() {
    this.saveAvailable = !!this.externalLocations.find(this.isSelectedLocations);
  }

  private isSelectedLocations(viewModel: IExternalLocationEditViewModel): boolean
  {
    return viewModel.selectedLocation.id > Constants.Locations.NotSelected;
  }

  public saveLocations() {
    
    const locationsToSave = this.externalLocations.filter(this.isSelectedLocations)
    .map<IOuterLocations>(_=> {
      return  { 
        Id: _.selectedLocation.id,
        Name: _.selectedLocation.name,
        Type: _.selectedLocation.locationType,
        ExternalId: _.externalId,
        VendorId : 0 // Looks like this field not used on backend
      }
    });

    this.settingsService
      .saveLocations(locationsToSave, this.currentVendor, this.isBarcodeProduct)
      .pipe(
        tap(_ => {
          if (this.isProductCopy) {
            this.errorMessageService.info = 'Transfering products...';
          }
          this.loading = true;
        }),
        switchMap(_ => {
          return this.setupService.initialConfig(this.isProductCopy, this.data);
        }),
        tap(_ => {
          this.navigationService.toDefaultPagePreserve();
          this.loading = false;
          this.saveAvailable = false;
        }),
        catchError(_=> {
          this.loading = false;
          return throwError(_);
        })
      )
      .subscribe();
  }
}